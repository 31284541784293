<template>
    <div>
      <div v-if="$store.state.userAgent === 'Mobile'">
          <div class="web-box" id="web-box">
            <div v-for="list in lists" :key="list.id">
              <div class="title">{{ list.label }}</div>
              <div class="block">
                <div class="row" v-for="(web) in list.website" :key="web.id" @click="goThirdPartyPage(web)">
                  <div class="img">
                    <img :src="web.img" alt="" width="40">
                  </div>
                  <div class="content">
                    <div class="text">{{ web.text }}</div>
                    <div class="desc">{{ web.desc }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Main v-if="$route.path.includes('web') && $store.state.userAgent === 'Mobile'" @resetScrollTop="resetScrollTop"></Main>
      </div>
      <div v-else class="web-pc-box" id="web-pc-box">
        <div class="sidebar">
          <div v-for="(list) in lists" :key="list.id" :class="[list.isSelected ? 'item-focus' : 'item']" @click="handleClick(list)">{{ list.label }}</div>
        </div>
        <div class="card-box">
          <div class="card" v-for="(web) in allWebsite" :key="web.id" @click="goThirdPartyPage(web)">
            <div class="img">
              <img :src="web.img" alt="" width="40">
            </div>
            <div class="content">
              <div class="text">{{ web.text }}</div>
              <div class="desc">{{ web.desc }}</div>
            </div>
          </div>
          <div class="load-more-bottom">已经到底部啦！</div>
        </div>
      </div>
    </div>
</template>

<script>
import Main from '../../components/footer/Main.vue';
import { wxSdkShare } from '@/utils/index';
import { allWebsite } from '../../assets/js/allWebsite'
const seo = require('../../../config/index')
// import { queryCouponById } from '@/api/api'

export default {
  name: 'Web',
  components: {
    Main
  },
  created(){
    this.initData()
  },
  mounted(){
    if(this.$store.state.userAgent !== 'Mobile'){
        document.getElementById("web-pc-box").style.cssText= 'margin-top: 65px';
    }
    this.wxShareInit()
  },
  data:()=>{
    return {
      scroll: 0,
      allWebsite: allWebsite,
      lists:[{
        id:'',
        label:'全部',
        website: [],
        isSelected:true
      },
      // {
      //   id:'hot',
      //   label:'热门',
      //   website: [],
      //   isSelected:false
      // },
      {
        id:'localAI',
        label:'国内AI',
        website: [],
        isSelected:false
      },{
        id:'textGen',
        label:'AI文字生成',
        website: [],
        isSelected:false
      },{
        id:'imageGen',
        label:'AI图像生成',
        website: [],
        isSelected:false
      },{
        id:'musicGen',
        label:'AI音频生成',
        website: [],
        isSelected:false
      },{
        id:'videoGen',
        label:'AI视频生成',
        website: [],
        isSelected:false
      },{
        id:'codeAssistant',
        label:'AI编程助手',
        website: [],
        isSelected:false
      },{
        id:'officeAssistant',
        label:'AI办公助手',
        website: [],
        isSelected:false
      },{
        id:'eduAssistant',
        label:'AI学习助手',
        website: [],
        isSelected:false
      },{
        id:'chatRobot',
        label:'聊天机器人',
        website: [],
        isSelected:false
      },{
        id:'digitalHuman',
        label:'虚拟数字人',
        website: [],
        isSelected:false
      },{
        id:'LLM',
        label:'大模型预训练',
        website: [],
        isSelected:false
      },{
        id:'promptTool',
        label:'提示词Prompt',
        website: [],
        isSelected:false
      }]
    }
  },
  methods:{
    initData(){
      let listsTemp = this.lists
      this.lists = listsTemp.map(list=>{
        let itemTemp = {} 
        let allWebsiteTemp = []
        allWebsite.forEach((item)=>{
          if(item.type.includes(list.id)){
            allWebsiteTemp.push(item)
          }
        })
        itemTemp.id = list.id
        itemTemp.label = list.label
        itemTemp.isSelected = list.id.length === 0 ? true : false
        itemTemp.website = allWebsiteTemp
        return itemTemp
      })
    },
    resetScrollTop(){
        this.scroll = 0
        document.body.scrollTop && document.body.scrollTo({top: 0, behavior: 'smooth'})
        document.documentElement.scrollTop && document.documentElement.scrollTo({top: 0, behavior: 'smooth'})
    },
    goThirdPartyPage(web){
      window.open(web.url,'_blank')
    },
    handleClick(list){
      let allWebsiteTemp = []
      allWebsite.forEach((web)=>{
        if(web.type.includes(list.id)){
          allWebsiteTemp.push(web)
        }
      })
      let listsTemp = this.lists
      this.lists = listsTemp.map(item=>{
        let itemTemp = {}
        if(item.id === list.id){
          itemTemp.id = item.id
          itemTemp.label = item.label
          itemTemp.isSelected = true
        } else {
          itemTemp.id = item.id
          itemTemp.label = item.label
          itemTemp.isSelected = false
        }
        return itemTemp
      })
      this.allWebsite = allWebsiteTemp
      window.scrollTo(0,0)
    },
    wxShareInit(){
      let params = {}
      params.url = seo.config.wxConfig.webPage.link + this.$route.fullPath
      params.context = seo.config.wxConfig.webPage.desc
      params.title = seo.config.wxConfig.webPage.title
      params.imgUrl = seo.config.wxConfig.webPage.imgUrl
      params.appId = seo.config.wxConfig.appId
      wxSdkShare(params)
    }
  }
}
</script>

<style scoped lang="scss">
.web-box {
  padding: 0px 15px 0;
  margin-bottom: calc(55px + constant(safe-area-inset-bottom));
  margin-bottom: calc(55px + env(safe-area-inset-bottom));
  .title {
    text-align: left;
    background: #ededed;
    margin: 0 -15px;
    padding: 5px 15px;
    color: rgba(0, 0, 0, 0.5);
  }
  .block::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .block {
    margin-top: 15px;
    display: flex;
    flex-flow: column wrap;
    height: 190px;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    .row {
      width: 75%;
      display: flex;
      align-items: center;
      .img {
        img {
          border-radius: 50%;
        }
      }
    }
    .content {
      text-align: left;
      padding: 10px;
      font-size: 14px;
      .text {
        margin: 0 0 2px 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;    
        -webkit-line-clamp: 1;    
        overflow: hidden;
      }
      .desc {
        font-size: 12px;
        color: #969699;
        display: -webkit-box;
        -webkit-box-orient: vertical;    
        -webkit-line-clamp: 1;  
        overflow: hidden;
      }
    }
  }
}

.web-pc-box {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: 0 10px;
  .sidebar {
    width: 15%;
    display: flex;
    flex-direction: column;
    position: fixed;
    text-align: left;
    background: #fff;
    .item {
      background: #fff;
      padding: 10px 15px;
      box-sizing: border-box;
      border: 1px solid rgba(0,0,0,0.01);
      font-size: 14px;
      color: rgba(0, 0, 0, 0.8);
      cursor: pointer;
    }
    .item-focus {
      background: #fff;
      padding: 10px 15px;
      box-sizing: border-box;
      border: 1px solid rgba(0,0,0,0.01);
      font-size: 14px;
      color: rgba(0, 0, 0, 0.8);
      cursor: pointer;
      color: #ec745b;
    }
  }
  .card-box {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-left: 15%;
    margin-top: -7.5px;
    .card {
      background: #FFF;
      display: flex;
      align-items: center;
      width: calc(25% - 40px);
      margin: 7.5px 10px;
      padding: 10px;
      min-height: 50px;
      border-radius: 5px;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
      }
      .img {
        min-width: 40px;
        min-height: 40px;
      }
      .content {
        text-align: left;
        padding-left: 10px;
        .text {
          padding: 2px 0;
        }
        .desc {
          font-size: 12px;
          color: #969699;
          display: -webkit-box;
          -webkit-box-orient: vertical;    
          -webkit-line-clamp: 2;    
          overflow: hidden;
        }
      }
    }
  }
}
.load-more-bottom {
  width: 100%;
  text-align: center;
  color: #969699;
  margin: 10px 0 10px 0;
  font-size: 14px;
}
</style>