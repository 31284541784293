const seo = require('../../../config/index')
// 默认按类型排序
export const allWebsite = [
  {
    id: "openai",
    url: "https://chat.openai.com/",
    img: seo.config.homeUrl + "/public/images/openai.png",
    type: "chatRobot",
    text: "ChatGPT",
    desc: "OpenAI的GPT-3.5以及GPT-4对话工具",
  },
  {
    id: "monica",
    url: "https://monica.im/",
    img: seo.config.homeUrl + "/public/images/monica.jpg",
    type: "chatRobot",
    text: "Monica",
    desc: "GPT-4的Edge和Chrome插件",
  },
  {
    id: "merlin",
    url: "https://merlin.foyer.work/",
    img: seo.config.homeUrl + "/public/images/merlin.jpg",
    type: "chatRobot",
    text: "Merlin",
    desc: "Chrome浏览器的ChatGPT插件",
  },
  {
    id: "bard",
    url: "https://bard.google.com/?hl=en",
    img: seo.config.homeUrl + "/public/images/bard.jpg",
    type: "chatRobot",
    text: "Bard",
    desc: "谷歌的AI对话机器人",
  },
  {
    id: "claude",
    url: "https://www.anthropic.com/earlyaccess",
    img: seo.config.homeUrl + "/public/images/anthropic.jpg",
    type: "chatRobot",
    text: "Claude",
    desc: "AI对话工具",
  },
  {
    id: "huggingchat",
    url: "https://huggingface.co/chat/",
    img: seo.config.homeUrl + "/public/images/huggingchat.jpg",
    type: "chatRobot",
    text: "HuggingChat",
    desc: "Hugging Face的聊天机器人",
  },
  {
    id: "poe",
    url: "https://poe.com/",
    img: seo.config.homeUrl + "/public/images/poe.jpg",
    type: "chatRobot",
    text: "Poe",
    desc: "Poe的聊天机器人",
  },
  {
    id: "colossalai",
    url: "https://chat.colossalai.org/",
    img: seo.config.homeUrl + "/public/images/colossalai.jpg",
    type: "chatRobot",
    text: "ColossalChat",
    desc: "Colossal的聊天机器人",
  },
  {
    id: "you",
    url: "https://you.com/search?q=who+are+you&tbm=youchat&cfr=chat",
    img: seo.config.homeUrl + "/public/images/you.jpg",
    type: "chatRobot",
    text: "YOU",
    desc: "YOU的聊天机器人",
  },
  {
    id: "writesonicchat",
    url: "https://writesonic.com/chat",
    img: seo.config.homeUrl + "/public/images/writesonic.jpg",
    type: "chatRobot",
    text: "Chatsonic",
    desc: "writesonic旗下的类ChatGPT的聊天机器人",
  },
  {
    id: "whispr",
    url: "https://whispr.chat/",
    img: seo.config.homeUrl + "/public/images/whispr.jpg",
    type: "chatRobot",
    text: "Whispr",
    desc: "AI对话工具",
  },
  {
    id: "openassistant",
    url: "https://open-assistant.io/zh",
    img: seo.config.homeUrl + "/public/images/openassistant.jpg",
    type: "chatRobot",
    text: "Open Assistant",
    desc: "AI聊天机器人",
  },
  {
    id: "heypi",
    url: "https://heypi.com/talk",
    img: seo.config.homeUrl + "/public/images/heypi.jpg",
    type: "chatRobot",
    text: "Pi",
    desc: "极简的AI聊天机器人",
  },
  {
    id: "character",
    url: "https://beta.character.ai/",
    img: seo.config.homeUrl + "/public/images/character.jpg",
    type: "chatRobot|digitalHuman",
    text: "character.ai",
    desc: "character的虚拟人AI对话",
  },
  {
    id: "replika",
    url: "https://replika.com/",
    img: seo.config.homeUrl + "/public/images/replika.png",
    type: "chatRobot|digitalHuman",
    text: "Replika",
    desc: "AI虚拟人",
  },
  {
    id: "copilot",
    url: "https://github.com/features/copilot",
    img: seo.config.homeUrl + "/public/images/copilot.jpg",
    type: "codeAssistant",
    text: "Copilot",
    desc: "GitHub的AI编程助手",
  },
  {
    id: "codesnippets",
    url: "https://codesnippets.ai/",
    img: seo.config.homeUrl + "/public/images/codesnippets.png",
    type: "codeAssistant",
    text: "Code Snippets AI",
    desc: "与最新的AI模型在您的编辑器中聊天, 构建您的代码片段库。",
  },
  {
    id: "huggingface",
    url: "https://huggingface.co/",
    img: seo.config.homeUrl + "/public/images/huggingface.png",
    type: "codeAssistant",
    text: "HuggingFace",
    desc: "AI开发相关的社区",
  },
  {
    id: "codex",
    url: "https://openai.com/blog/openai-codex",
    img: seo.config.homeUrl + "/public/images/codex.png",
    type: "codeAssistant",
    text: "Codex",
    desc: "OpenAI的自然语言生成代码的AI系统",
  },
  {
    id: "langchain",
    url: "https://docs.langchain.com/docs",
    img: seo.config.homeUrl + "/public/images/langchain.jpg",
    type: "codeAssistant",
    text: "Lang Chain",
    desc: "由语言模型驱动的应用程序开发框架",
  },
  {
    id: "nltk",
    url: "https://www.nltk.org/",
    img: seo.config.homeUrl + "/public/images/nltk.jpg",
    type: "codeAssistant",
    text: "NLTK",
    desc: "Python的自然语言处理工具包",
  },
  {
    id: "jax",
    url: "https://jax.readthedocs.io/en/latest/beginner_guide.html#beginner-guide",
    img: seo.config.homeUrl + "/public/images/jax.jpg",
    type: "codeAssistant",
    text: "JAX",
    desc: "Google的变换数值函数机器学习框架",
  },
  {
    id: "DeepLearning4J",
    url: "https://deeplearning4j.konduit.ai/",
    img: seo.config.homeUrl + "/public/images/DeepLearning4J.jpg",
    type: "codeAssistant",
    text: "DeepLearning4J",
    desc: "运行在JVM的深度学习框架的工具包",
  },
  {
    id: "numpy",
    url: "https://numpy.org/",
    img: seo.config.homeUrl + "/public/images/numpy.jpg",
    type: "codeAssistant",
    text: "NumPy",
    desc: "Python的科学计算基础包",
  },
  {
    id: "caffe",
    url: "https://caffe.berkeleyvision.org/",
    img: seo.config.homeUrl + "/public/images/caffe.jpg",
    type: "codeAssistant",
    text: "Caffe",
    desc: "加州大学伯克利分校的深度学习框架",
  },
  {
    id: "keras",
    url: "https://keras.io/",
    img: seo.config.homeUrl + "/public/images/keras.jpg",
    type: "codeAssistant",
    text: "Keras",
    desc: "TensorFlow的深度学习API",
  },
  {
    id: "scikit",
    url: "http://scikit-learn.github.io/stable",
    img: seo.config.homeUrl + "/public/images/scikit.jpg",
    type: "codeAssistant",
    text: "scikit-learn",
    desc: "Python的机器学习框架",
  },
  {
    id: "mxnet",
    url: "https://mxnet.apache.org/versions/1.9.1/",
    img: seo.config.homeUrl + "/public/images/mxnet.jpg",
    type: "codeAssistant",
    text: "Apache Mxnet",
    desc: "Apache开源的深度学习框架",
  },
  {
    id: "pytorch",
    url: "https://pytorch.org/",
    img: seo.config.homeUrl + "/public/images/pytorch.jpg",
    type: "codeAssistant",
    text: "Pytorch",
    desc: "动态神经网络框架",
  },
  {
    id: "tensorflow",
    url: "https://www.tensorflow.org/?hl=zh-cn",
    img: seo.config.homeUrl + "/public/images/tensorflow.jpg",
    type: "codeAssistant",
    text: "TensorFlow",
    desc: "Google开源的机器学习框架",
  },
  {
    id: "raycast",
    url: "https://www.raycast.com/pro",
    img: seo.config.homeUrl + "/public/images/raycast.jpg",
    type: "codeAssistant",
    text: "Raycast",
    desc: "Mac桌面AI助理",
  },
  {
    id: "fig",
    url: "https://fig.io/",
    img: seo.config.homeUrl + "/public/images/fig.jpg",
    type: "codeAssistant",
    text: "Fig",
    desc: "MAC命令行工具",
  },
  {
    id: "warp",
    url: "https://www.warp.dev/",
    img: seo.config.homeUrl + "/public/images/warp.jpg",
    type: "codeAssistant",
    text: "Warp",
    desc: "AI命令行终端工具",
  },
  {
    id: "debuild",
    url: "https://debuild.app/",
    img: seo.config.homeUrl + "/public/images/debuild.jpg",
    type: "codeAssistant",
    text: "Debuild",
    desc: "制作网站的AI驱动的低代码工具",
  },
  {
    id: "mutable",
    url: "https://mutable.ai/",
    img: seo.config.homeUrl + "/public/images/mutable.jpg",
    type: "codeAssistant",
    text: "Mutable.ai",
    desc: "AI加速软件开发工具",
  },
  {
    id: "tabnine",
    url: "https://www.tabnine.com/",
    img: seo.config.homeUrl + "/public/images/tabnine.jpg",
    type: "codeAssistant",
    text: "Tabnine",
    desc: "快速代码开发助手",
  },
  {
    id: "marsx",
    url: "https://www.marsx.dev/",
    img: seo.config.homeUrl + "/public/images/marsx.jpg",
    type: "codeAssistant",
    text: "MarsX",
    desc: "Code + NoCode + AI的开发工具",
  },
  {
    id: "fronty",
    url: "https://fronty.com/",
    img: seo.config.homeUrl + "/public/images/fronty.jpg",
    type: "codeAssistant",
    text: "Fronty",
    desc: "上传图片生成前端代码的网站",
  },
  {
    id: "locofy",
    url: "https://www.locofy.ai/",
    img: seo.config.homeUrl + "/public/images/locofy.jpg",
    type: "codeAssistant",
    text: "Locofy",
    desc: "AI加速前端开发的网站",
  },
  {
    id: "codiga",
    url: "https://www.codiga.io/",
    img: seo.config.homeUrl + "/public/images/codiga.jpg",
    type: "codeAssistant",
    text: "Codiga",
    desc: "开发环境的代码分析工具",
  },
  {
    id: "replit",
    url: "https://replit.com/site/ghostwriter",
    img: seo.config.homeUrl + "/public/images/replit.jpg",
    type: "codeAssistant",
    text: "Replit",
    desc: "IDE中，提高代码开发效率的AI工具",
  },
  {
    id: "airops",
    url: "https://www.airops.com/",
    img: seo.config.homeUrl + "/public/images/airops.jpg",
    type: "codeAssistant",
    text: "Airops",
    desc: "使用AI编写SQL的工具",
  },
  {
    id: "askcodi",
    url: "https://www.askcodi.com/",
    img: seo.config.homeUrl + "/public/images/askcodi.jpg",
    type: "codeAssistant",
    text: "Ask Codi",
    desc: "AI编程插件",
  },
  {
    id: "cursor",
    url: "https://www.cursor.so/",
    img: seo.config.homeUrl + "/public/images/cursor.jpg",
    type: "codeAssistant",
    text: "Cursor",
    desc: "AI代码编辑器",
  },
  {
    id: "obviously",
    url: "https://www.obviously.ai/",
    img: seo.config.homeUrl + "/public/images/obviously.jpg",
    type: "codeAssistant",
    text: "Obviously.ai",
    desc: "数据科学无代码工具",
  },
  {
    id: "codewhisperer",
    url: "https://aws.amazon.com/cn/codewhisperer/",
    img: seo.config.homeUrl + "/public/images/codewhisperer.jpg",
    type: "codeAssistant",
    text: "CodeWhisperer",
    desc: "亚马逊的AI代码生成器",
  },
  {
    id: "codeium",
    url: "https://codeium.com/",
    img: seo.config.homeUrl + "/public/images/codeium.jpg",
    type: "codeAssistant",
    text: "Codeium",
    desc: "AI编程插件",
  },
  {
    id: "useblackbox",
    url: "https://www.useblackbox.io/",
    img: seo.config.homeUrl + "/public/images/useblackbox.jpg",
    type: "codeAssistant",
    text: "BLACKBOX AI",
    desc: "VSCode AI编程插件",
  },
  {
    id: "codium",
    url: "https://www.codium.ai/",
    img: seo.config.homeUrl + "/public/images/codium.jpg",
    type: "codeAssistant",
    text: "Codium",
    desc: "AI测试代码生成工具",
  },
  {
    id: "hocoos",
    url: "https://hocoos.com/",
    img: seo.config.homeUrl + "/public/images/hocoos.jpg",
    type: "codeAssistant",
    text: "Hocoos",
    desc: "AI建站平台，只需回答8个问题",
  },
  {
    id: "httpie",
    url: "https://httpie.io/ai",
    img: seo.config.homeUrl + "/public/images/httpie.jpg",
    type: "codeAssistant",
    text: "Httpie AI",
    desc: "AI版API工具",
  },
  {
    id: "aicodereviewer",
    url: "https://ai-code-reviewer.com/",
    img: seo.config.homeUrl + "/public/images/aicodereviewer.jpg",
    type: "codeAssistant",
    text: "AI Code Reviewer",
    desc: "AI代码代码检视工具",
  },
  {
    id: "intellicode",
    url: "https://visualstudio.microsoft.com/zh-hans/services/intellicode/",
    img: seo.config.homeUrl + "/public/images/intellicode.jpg",
    type: "codeAssistant",
    text: "Visual Studio IntelliCode",
    desc: "将AI助手直接引入你的个人开发流",
  },
  {
    id: "heycli",
    url: "https://www.heycli.com/",
    img: seo.config.homeUrl + "/public/images/heycli.jpg",
    type: "codeAssistant",
    text: "HeyCLI",
    desc: "自然语言和命令CLI转换工具",
  },
  {
    id: "inworld",
    url: "https://www.inworld.ai/",
    img: seo.config.homeUrl + "/public/images/inworld.jpg",
    type: "codeAssistant|digitalHuman",
    text: "Inworld",
    desc: "虚拟人开发平台",
  },
  {
    id: "paddlepaddle",
    url: "https://www.paddlepaddle.org.cn/",
    img: seo.config.homeUrl + "/public/images/paddlepaddle.png",
    type: "codeAssistant|LLM|localAI",
    text: "百度飞桨",
    desc: "源于产业实践的开源深度学习平台",
  },
  {
    id: "mindspore",
    url: "https://www.mindspore.cn/",
    img: seo.config.homeUrl + "/public/images/mindspore.jpg",
    type: "codeAssistant|localAI",
    text: "昇思",
    desc: "华为开源的深度学习框架",
  },
  {
    id: "deco",
    url: "https://ling-deco.jd.com/",
    img: seo.config.homeUrl + "/public/images/deco.jpg",
    type: "codeAssistant|localAI",
    text: "DECO",
    desc: "京东旗下设计稿一键生成多端代码工具",
  },
  {
    id: "aixcoder",
    url: "https://www.aixcoder.com/#/",
    img: seo.config.homeUrl + "/public/images/aixcoder.jpg",
    type: "codeAssistant|localAI",
    text: "AIXcoder",
    desc: "国内智能编程机器人",
  },
  {
    id: "codegeex",
    url: "https://codegeex.cn/zh-CN",
    img: seo.config.homeUrl + "/public/images/codegeex.jpg",
    type: "codeAssistant|localAI",
    text: "CodeGeeX",
    desc: "清华大学免费的AI编程助手",
  },
  {
    id: "skycode",
    url: "https://sky-code.singularity-ai.com/index.html#/",
    img: seo.config.homeUrl + "/public/images/skycode.jpg",
    type: "codeAssistant|localAI",
    text: "SKYCODE",
    desc: "奇点智源的AI智能编程助手 轻松生成各种代码",
  },
  {
    id: "typecast",
    url: "https://typecast.ai/",
    img: seo.config.homeUrl + "/public/images/typecast.png",
    type: "digitalHuman",
    text: "Typecast",
    desc: "AI虚拟人",
  },
  {
    id: "laihua",
    url: "https://www.laihua.com/",
    img: seo.config.homeUrl + "/public/images/laihua.jpg",
    type: "digitalHuman|localAI",
    text: "来画",
    desc: "来画-动画和数字人智能生成平台",
  },
  {
    id: "wondershare",
    url: "https://virbo.wondershare.cn/",
    img: seo.config.homeUrl + "/public/images/wondershare.jpg",
    type: "digitalHuman|localAI",
    text: "万兴播报",
    desc: '万兴科技旗下的AIGC"真人"短视频出海营销神器',
  },
  {
    id: "weta365",
    url: "https://weta365.com/conduct",
    img: seo.config.homeUrl + "/public/images/weta365.jpg",
    type: "digitalHuman|localAI",
    text: "奇妙元",
    desc: "专注于数字人短视频&直播服务",
  },
  {
    id: "quizify",
    url: "https://quizify.com/",
    img: seo.config.homeUrl + "/public/images/quizify.png",
    type: "eduAssistant",
    text: "Quizify",
    desc: "告别低效和乏味的学习。Quizify通过增加游戏化元素，革新了学习体验，采用经过验证的方法论，让您轻松掌握任何学科。",
  },
  {
    id: "googleai",
    url: "https://ai.google/",
    img: seo.config.homeUrl + "/public/images/googleai.jpg",
    type: "eduAssistant",
    text: "Google AI",
    desc: "Google的AI学习社区",
  },
  {
    id: "udacity",
    url: "https://www.udacity.com/school/school-of-ai",
    img: seo.config.homeUrl + "/public/images/udacity.jpg",
    type: "eduAssistant",
    text: "Udacity",
    desc: "优达学城的AI学习学院",
  },
  {
    id: "fastai",
    url: "https://www.fast.ai/",
    img: seo.config.homeUrl + "/public/images/fastai.jpg",
    type: "eduAssistant",
    text: "fast.ai",
    desc: "免费开源的深度学习和AI学习网站",
  },
  {
    id: "coursera",
    url: "https://www.coursera.org/collections/best-machine-learning-ai",
    img: seo.config.homeUrl + "/public/images/coursera.jpg",
    type: "eduAssistant",
    text: "Coursera",
    desc: "MOOC课程学习平台",
  },
  {
    id: "elementsofai",
    url: "https://www.elementsofai.com/",
    img: seo.config.homeUrl + "/public/images/elementsofai.jpg",
    type: "eduAssistant",
    text: "Elements of AI",
    desc: "免费的AI在线课程",
  },
  {
    id: "deeplearningai",
    url: "https://www.deeplearning.ai/",
    img: seo.config.homeUrl + "/public/images/deeplearningai.jpg",
    type: "eduAssistant",
    text: "Deeplearning.AI",
    desc: "深度学习的课程平台",
  },
  {
    id: "brilliant",
    url: "https://brilliant.org/courses/intro-neural-networks/",
    img: seo.config.homeUrl + "/public/images/brilliant.jpg",
    type: "eduAssistant",
    text: "Brilliant",
    desc: "神经网络入门课程",
  },
  {
    id: "microsoft",
    url: "https://microsoft.github.io/ML-For-Beginners/#/",
    img: seo.config.homeUrl + "/public/images/microsoft.jpg",
    type: "eduAssistant",
    text: "ML for Beginners",
    desc: "微软的免费机器学习课程",
  },
  {
    id: "showmeai",
    url: "https://www.showmeai.tech/",
    img: seo.config.homeUrl + "/public/images/showmeai.png",
    type: "eduAssistant|localAI",
    text: "Show Me AI",
    desc: "数据科学与人工智能领域的资料库学习社区",
  },
  {
    id: "aidaxue",
    url: "https://www.aidaxue.com/",
    img: seo.config.homeUrl + "/public/images/aidaxue.jpg",
    type: "eduAssistant|localAI",
    text: "AI大学堂",
    desc: "科大讯飞旗下的AI学堂",
  },
  {
    id: "aliyunai",
    url: "https://developer.aliyun.com/learning/roadmap/ai",
    img: seo.config.homeUrl + "/public/images/aliyunai.png",
    type: "eduAssistant|localAI",
    text: "人工智能学习路线",
    desc: "阿里云的AI学习路线",
  },
  {
    id: "d2l",
    url: "https://zh.d2l.ai/",
    img: seo.config.homeUrl + "/public/images/d2l.png",
    type: "eduAssistant|localAI",
    text: "动手学深度学习",
    desc: "深度学习的教程和课程",
  },
  {
    id: "aistudio",
    url: "https://aistudio.baidu.com/aistudio/index",
    img: seo.config.homeUrl + "/public/images/aistudio.png",
    type: "eduAssistant|localAI",
    text: "AI Studio",
    desc: "百度飞桨的人工智能课程",
  },
  {
    id: "midjourney",
    url: "https://www.midjourney.com/home/?callbackUrl=%2Fapp%2F",
    img: seo.config.homeUrl + "/public/images/midjourney.jpg",
    type: "imageGen",
    text: "Midjourney",
    desc: "业界有影响力的头部图片生成应用",
  },
  {
    id: "civitai",
    url: "https://civitai.com/",
    img: seo.config.homeUrl + "/public/images/civitai.png",
    type: "imageGen",
    text: "Civitai",
    desc: "Civitai是AI艺术生成社区的唯一模型共享中心！免费使用、开源，并不断改进。",
  },
  {
    id: "dreamstudio",
    url: "https://beta.dreamstudio.ai/generate",
    img: seo.config.homeUrl + "/public/images/dreamstudio.png",
    type: "imageGen",
    text: "Dream Studio",
    desc: "Stability-AI的图像生成工具",
  },
  {
    id: "saxifrage",
    url: "https://tools.saxifrage.xyz/prompt",
    img: seo.config.homeUrl + "/public/images/saxifrage.png",
    type: "imageGen",
    text: "Visual Prompt Builder",
    desc: "提示词可视化图片生成平台",
  },
  {
    id: "iconifyai",
    url: "https://www.iconifyai.com/",
    img: seo.config.homeUrl + "/public/images/iconifyai.jpg",
    type: "imageGen",
    text: "Iconify AI",
    desc: "AI图标设计网站",
  },
  {
    id: "illustroke",
    url: "https://illustroke.com/",
    img: seo.config.homeUrl + "/public/images/illustroke.jpg",
    type: "imageGen",
    text: "illustroke",
    desc: "SVG矢量图AI生成器",
  },
  {
    id: "alpaca",
    url: "https://www.alpacaml.com/",
    img: seo.config.homeUrl + "/public/images/alpaca.jpg",
    type: "imageGen",
    text: "Alpaca",
    desc: "AI图片设计工具",
  },
  {
    id: "khroma",
    url: "https://www.khroma.co/",
    img: seo.config.homeUrl + "/public/images/khroma.jpg",
    type: "imageGen",
    text: "Khroma",
    desc: "AI图片设计工具",
  },
  {
    id: "playgroundai",
    url: "https://playgroundai.com/",
    img: seo.config.homeUrl + "/public/images/playgroundai.jpg",
    type: "imageGen",
    text: "Playgroundai AI",
    desc: "在线文生图网站,展示大量精美图片",
  },
  {
    id: "bgeraser",
    url: "https://bgeraser.com/",
    img: seo.config.homeUrl + "/public/images/bgeraser.jpg",
    type: "imageGen",
    text: "Bg Eraser",
    desc: "图片去背景网站",
  },
  {
    id: "snapedit",
    url: "https://snapedit.app/",
    img: seo.config.homeUrl + "/public/images/snapedit.jpg",
    type: "imageGen",
    text: "SnapEdit",
    desc: "在线快速抠图网站",
  },
  {
    id: "cleanup",
    url: "https://cleanup.pictures/",
    img: seo.config.homeUrl + "/public/images/cleanup.jpg",
    type: "imageGen",
    text: "Cleanup.pictures",
    desc: "在线快速抠图工具",
  },
  {
    id: "cutoutimageretouch",
    url: "https://www.cutout.pro/zh-CN/image-retouch-remove-unwanted-objects",
    img: seo.config.homeUrl + "/public/images/cutout.jpg",
    type: "imageGen",
    text: "cutout.pro",
    desc: "cutout.pro旗下的在线修图网站",
  },
  {
    id: "magiceraser",
    url: "https://magicstudio.com/zh/magiceraser/",
    img: seo.config.homeUrl + "/public/images/magiceraser.jpg",
    type: "imageGen",
    text: "Magic Eraser",
    desc: "magicstudio旗下的AI在几秒钟内删除不需要的东西",
  },
  {
    id: "watermarkremover",
    url: "https://www.watermarkremover.io/zh",
    img: seo.config.homeUrl + "/public/images/watermarkremover.jpg",
    type: "imageGen",
    text: "WatermarkRemover.io",
    desc: "AI从图像中删除水印免费",
  },
  {
    id: "msdesigner",
    url: "https://designer.microsoft.com/",
    img: seo.config.homeUrl + "/public/images/msdesigner.png",
    type: "imageGen",
    text: "Microsoft Designer",
    desc: "微软旗下的在线营销海报设计工具",
  },
  {
    id: "looka",
    url: "https://looka.com/",
    img: seo.config.homeUrl + "/public/images/looka.jpg",
    type: "imageGen",
    text: "Looka",
    desc: "品牌logo在线设计",
  },
  {
    id: "nightcafe",
    url: "https://creator.nightcafe.studio/",
    img: seo.config.homeUrl + "/public/images/nightcafe.png",
    type: "imageGen",
    text: "NightCafe",
    desc: "AI艺术插画在线生成网站",
  },
  {
    id: "bluewillow",
    url: "https://www.bluewillow.ai/",
    img: seo.config.homeUrl + "/public/images/bluewillow.jpg",
    type: "imageGen",
    text: "BlueWillow",
    desc: "商标、数字艺术品、AI图片，免费试用生成",
  },
  {
    id: "deepdreamgenerator",
    url: "https://deepdreamgenerator.com/",
    img: seo.config.homeUrl + "/public/images/deepdreamgenerator.jpg",
    type: "imageGen",
    text: "Deep Dream Generator",
    desc: "AI插图生成器",
  },
  {
    id: "waifulabs",
    url: "https://waifulabs.com/",
    img: seo.config.homeUrl + "/public/images/waifulabs.jpg",
    type: "imageGen",
    text: "Waifulabs",
    desc: "免费的动漫头像AI生成网站",
  },
  {
    id: "nijijourney",
    url: "https://nijijourney.com/zh/",
    img: seo.config.homeUrl + "/public/images/nijijourney.jpg",
    type: "imageGen",
    text: "niji.journey",
    desc: "这是一个最先进的AI，可以绘制任何二次元风格的绘画！",
  },
  {
    id: "dreamlike",
    url: "https://dreamlike.art/",
    img: seo.config.homeUrl + "/public/images/dreamlike.jpg",
    type: "imageGen",
    text: "dreamlike.art",
    desc: "AI生成原创艺术图网站",
  },
  {
    id: "artbreeder",
    url: "https://www.artbreeder.com/",
    img: seo.config.homeUrl + "/public/images/artbreeder.jpg",
    type: "imageGen",
    text: "Artbreeder",
    desc: "AI处理图片混合、调参、补全等",
  },
  {
    id: "wepik",
    url: "https://wepik.com/ai",
    img: seo.config.homeUrl + "/public/images/wepik.jpg",
    type: "imageGen",
    text: "Wepik",
    desc: "AI文生图在线工具",
  },
  {
    id: "craiyon",
    url: "https://www.craiyon.com/",
    img: seo.config.homeUrl + "/public/images/craiyon.jpg",
    type: "imageGen",
    text: "Craiyon",
    desc: "免费的AI图片生成器，创作AI艺术作品",
  },
  {
    id: "writesonicpaiagen",
    url: "https://writesonic.com/photosonic-ai-art-generator",
    img: seo.config.homeUrl + "/public/images/writesonic.jpg",
    type: "imageGen",
    text: "Photosonic",
    desc: "writesonic旗下的AI艺术生成器",
  },
  {
    id: "astria",
    url: "https://www.astria.ai/",
    img: seo.config.homeUrl + "/public/images/astria.jpg",
    type: "imageGen",
    text: "Astria",
    desc: "量身定做的AI，开始创作独一无二的图片",
  },
  {
    id: "getimg",
    url: "https://getimg.ai/",
    img: seo.config.homeUrl + "/public/images/getimg.png",
    type: "imageGen",
    text: "getimg.ai",
    desc: "AI创建头像、艺术、图片、照片等",
  },
  {
    id: "dreamup",
    url: "https://www.dreamup.com/",
    img: seo.config.homeUrl + "/public/images/dreamup.jpg",
    type: "imageGen",
    text: "DreamUp",
    desc: "AI提示词创建图片网站",
  },
  {
    id: "scribblediffusion",
    url: "https://scribblediffusion.com/",
    img: seo.config.homeUrl + "/public/images/scribblediffusion.jpg",
    type: "imageGen",
    text: "Scribble Diffusion",
    desc: "AI在线草稿图转精美插图工具",
  },
  {
    id: "lexica",
    url: "https://lexica.art/",
    img: seo.config.homeUrl + "/public/images/lexica.jpg",
    type: "imageGen",
    text: "Lexica",
    desc: "基于Stable Diffusion的AI插画生成工具",
  },
  {
    id: "prodia",
    url: "https://app.prodia.com/",
    img: seo.config.homeUrl + "/public/images/prodia.jpg",
    type: "imageGen",
    text: "Prodia",
    desc: "AI艺术画生成工具",
  },
  {
    id: "generated",
    url: "https://generated.photos/",
    img: seo.config.homeUrl + "/public/images/generated.jpg",
    type: "imageGen",
    text: "Generated Photos",
    desc: "AI生成人像工具",
  },
  {
    id: "picsart",
    url: "https://picsart.com/ai-image-generator",
    img: seo.config.homeUrl + "/public/images/picsart.jpg",
    type: "imageGen",
    text: "Picsart",
    desc: "在线AI图片生成器",
  },
  {
    id: "imagine",
    url: "https://magicstudio.com/zh/imagine/",
    img: seo.config.homeUrl + "/public/images/imagine.jpg",
    type: "imageGen",
    text: "Imagine",
    desc: "magicstudio旗下的用文字制作图片，20张内免费",
  },
  {
    id: "neural",
    url: "https://neural.love/",
    img: seo.config.homeUrl + "/public/images/neural.jpg",
    type: "imageGen",
    text: "neural.love",
    desc: "免费的AI艺术生成器",
  },
  {
    id: "starryai",
    url: "https://starryai.com/",
    img: seo.config.homeUrl + "/public/images/starryai.png",
    type: "imageGen",
    text: "Starryai",
    desc: "在线AI艺术创作网站",
  },
  {
    id: "photobooth",
    url: "https://magicstudio.com/zh/photobooth/",
    img: seo.config.homeUrl + "/public/images/photobooth.jpg",
    type: "imageGen",
    text: "Photo Booth",
    desc: "magicstudio旗下的用AI创建令人惊叹的个人资料图片",
  },
  {
    id: "supermeme",
    url: "https://www.supermeme.ai/",
    img: seo.config.homeUrl + "/public/images/supermeme.jpg",
    type: "imageGen",
    text: "Super MeMe",
    desc: "使用AI Meme生成器将文本转换为meme",
  },
  {
    id: "dream",
    url: "https://dream.ai/create",
    img: seo.config.homeUrl + "/public/images/dream.jpg",
    type: "imageGen",
    text: "Dream",
    desc: "无限乐趣的AI平台",
  },
  {
    id: "icons8bgremover",
    url: "https://icons8.com/bgremover",
    img: seo.config.homeUrl + "/public/images/icons8bgremover.jpg",
    type: "imageGen",
    text: "AI Background Remover",
    desc: "icons8旗下的设计师的AI去背景",
  },
  {
    id: "clipdropremovebackground",
    url: "https://clipdrop.co/remove-background",
    img: seo.config.homeUrl + "/public/images/clipdrop.jpg",
    type: "imageGen",
    text: "REMOVE BACKGROUND",
    desc: "Clipdrop旗下的在线图片背景消除",
  },
  {
    id: "erase",
    url: "https://www.erase.bg/zh",
    img: seo.config.homeUrl + "/public/images/erase.jpg",
    type: "imageGen",
    text: "Erase bg",
    desc: "从人类、动物或物体的图像中移除背景，免费下载高分辨率图像",
  },
  {
    id: "bgsub",
    url: "https://bgsub.cn/",
    img: seo.config.homeUrl + "/public/images/bgsub.jpg",
    type: "imageGen",
    text: "BgSub",
    desc: "使用先进的人工智能技术自动替换图像的背景",
  },
  {
    id: "adobecc",
    url: "https://www.adobe.com/cn/",
    img: seo.config.homeUrl + "/public/images/adobe.jpg",
    type: "imageGen",
    text: "Adobe Creative Cloud",
    desc: "包含最新Photoshop，Lightroom Classic等应用，您可以将图像转换为您想象的任何内容",
  },
  {
    id: "removal",
    url: "https://removal.ai/",
    img: seo.config.homeUrl + "/public/images/removal.jpg",
    type: "imageGen",
    text: "removal.ai",
    desc: "免费的AI消除图片背景",
  },
  {
    id: "backgrounderaser",
    url: "https://magicstudio.com/zh/backgrounderaser/",
    img: seo.config.homeUrl + "/public/images/backgrounderaser.jpg",
    type: "imageGen",
    text: "Background Eraser",
    desc: "magicstudio旗下的自动删除背景，只需几秒钟",
  },
  {
    id: "slazzer",
    url: "https://www.slazzer.com/",
    img: seo.config.homeUrl + "/public/images/slazzer.jpg",
    type: "imageGen",
    text: "Slazzer",
    desc: "免费的自动化消除背景工具",
  },
  {
    id: "cutoutrb",
    url: "https://www.cutout.pro/zh-cn/remove-background",
    img: seo.config.homeUrl + "/public/images/cutout.jpg",
    type: "imageGen",
    text: "cutout.pro",
    desc: "cutout.pro旗下的在线免费批量图像背景去除器, 100%由AI自动处理",
  },
  {
    id: "vanceai",
    url: "https://bgremover.vanceai.com/",
    img: seo.config.homeUrl + "/public/images/vanceai.jpg",
    type: "imageGen",
    text: "Vance AI",
    desc: "Vance AI旗下的在线背景消除工具",
  },
  {
    id: "quicktools",
    url: "https://tools.picsart.com/image/background-remover/",
    img: seo.config.homeUrl + "/public/images/quicktools.jpg",
    type: "imageGen",
    text: "Quicktools",
    desc: "5秒内消除背景，一键生成",
  },
  {
    id: "zyro",
    url: "https://zyro.com/tools/image-background-remover",
    img: seo.config.homeUrl + "/public/images/zyro.jpg",
    type: "imageGen",
    text: "Zyro",
    desc: "在线AI一键移除背景，输出任意透明背景的图片",
  },
  {
    id: "photoscissors",
    url: "https://photoscissors.com/",
    img: seo.config.homeUrl + "/public/images/photoscissors.jpg",
    type: "imageGen",
    text: "Photo Scissors",
    desc: "几步即可移除图片背景",
  },
  {
    id: "clippingmagic",
    url: "https://clippingmagic.com/",
    img: seo.config.homeUrl + "/public/images/clippingmagic.png",
    type: "imageGen",
    text: "Clipping Magic",
    desc: "AI自动化、免费移除图片背景",
  },
  {
    id: "stylized",
    url: "https://www.stylized.ai/",
    img: seo.config.homeUrl + "/public/images/stylized.jpg",
    type: "imageGen",
    text: "Stylized",
    desc: "AI产品图片背景替换",
  },
  {
    id: "pebblely",
    url: "https://pebblely.com/",
    img: seo.config.homeUrl + "/public/images/pebblely.jpg",
    type: "imageGen",
    text: "Pebblely",
    desc: "电商产品图片快速优化",
  },
  {
    id: "mokker",
    url: "https://mokker.ai/",
    img: seo.config.homeUrl + "/public/images/mokker.jpg",
    type: "imageGen",
    text: "Mokker",
    desc: "AI背景替换",
  },
  {
    id: "booth",
    url: "https://www.booth.ai/",
    img: seo.config.homeUrl + "/public/images/booth.jpg",
    type: "imageGen",
    text: "Booth.ai",
    desc: "创建高质量的电商产品图片",
  },
  {
    id: "pixelhunter",
    url: "https://pixelhunter.io/",
    img: seo.config.homeUrl + "/public/images/pixelhunter.jpg",
    type: "imageGen",
    text: "Pixelhunter",
    desc: "海外社交平台图片适配",
  },
  {
    id: "upscaler",
    url: "https://icons8.com/upscaler",
    img: seo.config.homeUrl + "/public/images/upscaler.jpg",
    type: "imageGen",
    text: "Smart Upscaler",
    desc: "icons8旗下的图像增强，模糊图片转高清图",
  },
  {
    id: "clipdropimageupscaler",
    url: "https://clipdrop.co/image-upscaler",
    img: seo.config.homeUrl + "/public/images/clipdrop.jpg",
    type: "imageGen",
    text: "IMAGE UPSCALER",
    desc: "Clipdrop旗下的图像增强，在几秒钟内对图像进行升级、降噪和增强",
  },
  {
    id: "imgupscaler",
    url: "https://imgupscaler.com/",
    img: seo.config.homeUrl + "/public/images/imgupscaler.jpg",
    type: "imageGen",
    text: "Img.Upscaler",
    desc: "AI图像增强工具",
  },
  {
    id: "zyroimageupscaler",
    url: "https://zyro.com/tools/image-upscaler",
    img: seo.config.homeUrl + "/public/images/zyro.jpg",
    type: "imageGen",
    text: "AI Image Upscaler",
    desc: "zyro旗下的图像增强工具",
  },
  {
    id: "mediaimageupscaler",
    url: "https://www.media.io/image-upscaler.html",
    img: seo.config.homeUrl + "/public/images/mediaimageupscaler.jpg",
    type: "imageGen",
    text: "AI Image Upscaler",
    desc: "media.io旗下的扩大、增强和升级图像AI工具",
  },
  {
    id: "upscalemedia",
    url: "https://www.upscale.media/zh",
    img: seo.config.homeUrl + "/public/images/upscalemedia.jpg",
    type: "imageGen",
    text: "Upscale.media",
    desc: "在线改变图片分辨率工具",
  },
  {
    id: "neroimageupscaler",
    url: "https://ai.nero.com/image-upscaler",
    img: seo.config.homeUrl + "/public/images/neroimageupscaler.jpg",
    type: "imageGen",
    text: "Nero AI Image Upscaler",
    desc: "Nero AI旗下的图像放大工具，再也没有小而模糊的图像了",
  },
  {
    id: "vanceaiimageresizer",
    url: "https://vanceai.com/image-resizer/",
    img: seo.config.homeUrl + "/public/images/vanceai.jpg",
    type: "imageGen",
    text: "Image Resizer",
    desc: "Vance AI旗下的图片改尺寸工具",
  },
  {
    id: "photoaidimgenlarger",
    url: "https://photoaid.com/en/tools/ai-image-enlarger",
    img: seo.config.homeUrl + "/public/images/photoaidimgenlarger.jpg",
    type: "imageGen",
    text: "AI Image Enlarger",
    desc: "Photoaid旗下的图片改尺寸工具",
  },
  {
    id: "upscalepics",
    url: "https://upscalepics.com/",
    img: seo.config.homeUrl + "/public/images/upscalepics.jpg",
    type: "imageGen",
    text: "UpscalePics",
    desc: "放大和增强图像的在线工具",
  },
  {
    id: "magicstudioenlarger",
    url: "https://magicstudio.com/zh/enlarger/",
    img: seo.config.homeUrl + "/public/images/magicstudioenlarger.jpg",
    type: "imageGen",
    text: "Image Enlarger",
    desc: "magicstudio旗下的图像无损放大工具",
  },
  {
    id: "jpghd",
    url: "https://jpghd.com/zh",
    img: seo.config.homeUrl + "/public/images/jpghd.jpg",
    type: "imageGen",
    text: "jpgHD",
    desc: "人工智能老照片无损修复",
  },
  {
    id: "photocolorizerblackandwhite",
    url: "https://www.cutout.pro/zh-CN/photo-colorizer-black-and-white",
    img: seo.config.homeUrl + "/public/images/cutout.jpg",
    type: "imageGen",
    text: "cutout.pro",
    desc: "cutout.pro旗下的在线自动给黑白照片上色",
  },
  {
    id: "restorephotos",
    url: "https://www.restorephotos.io/",
    img: seo.config.homeUrl + "/public/images/restorephotos.jpg",
    type: "imageGen",
    text: "restorePhotos.io",
    desc: "修复老照片工具",
  },
  {
    id: "palette",
    url: "https://palette.fm/",
    img: seo.config.homeUrl + "/public/images/palette.jpg",
    type: "imageGen",
    text: "Palette",
    desc: "黑白图片上色工具",
  },
  {
    id: "bingcreate",
    url: "https://cn.bing.com/create",
    img: seo.config.homeUrl + "/public/images/bing.jpg",
    type: "imageGen",
    text: "图像创建器",
    desc: "微软必应旗下的基于DALL·E的图像创建工具",
  },
  {
    id: "upscayl",
    url: "https://www.upscayl.org/",
    img: seo.config.homeUrl + "/public/images/upscayl.jpg",
    type: "imageGen",
    text: "Upscayl",
    desc: "免费开放的AI图像增强网站",
  },
  {
    id: "removebg",
    url: "https://www.remove.bg/zh",
    img: seo.config.homeUrl + "/public/images/removebg.png",
    type: "imageGen",
    text: "Removebg",
    desc: "图片背景消除，100%全自动且免费",
  },
  {
    id: "ribbet",
    url: "https://ribbet.ai/",
    img: seo.config.homeUrl + "/public/images/ribbet.jpg",
    type: "imageGen",
    text: "Ribbet.ai",
    desc: "AI图片编辑，简单易用且免费",
  },
  {
    id: "photoroom",
    url: "https://www.photoroom.com/background-remover",
    img: seo.config.homeUrl + "/public/images/photoroom.png",
    type: "imageGen",
    text: "PhotoRoom",
    desc: "消除图片背景，自定义新背景",
  },
  {
    id: "leonardo",
    url: "https://leonardo.ai/",
    img: seo.config.homeUrl + "/public/images/leonardo.jpg",
    type: "imageGen",
    text: "Leonardo.Ai",
    desc: "游戏动漫插图制作平台",
  },
  {
    id: "clipdroprelight",
    url: "https://clipdrop.co/relight",
    img: seo.config.homeUrl + "/public/images/clipdrop.jpg",
    type: "imageGen",
    text: "RELIGHT",
    desc: "clipdrop旗下的照片和图画打光工具",
  },
  {
    id: "stockimg",
    url: "https://stockimg.ai/",
    img: seo.config.homeUrl + "/public/images/stockimg.jpg",
    type: "imageGen",
    text: "STOCKIMG.AI",
    desc: "AI生成封面、墙纸、海报、标识、图库图片、插图、艺术图",
  },
  {
    id: "diffus",
    url: "https://www.diffus.graviti.com/",
    img: seo.config.homeUrl + "/public/images/diffus.jpg",
    type: "imageGen",
    text: "Graviti Diffus",
    desc: "基于Stable Diffusion的WebUI托管服务",
  },
  {
    id: "picwish",
    url: "https://picwish.com/",
    img: seo.config.homeUrl + "/public/images/picwish.jpg",
    type: "imageGen",
    text: "PicWish",
    desc: "一款人工智能驱动的图像处理工具",
  },
  {
    id: "pixelcut",
    url: "https://www.pixelcut.ai/",
    img: seo.config.homeUrl + "/public/images/pixelcut.jpg",
    type: "imageGen",
    text: "Pixelcut",
    desc: "利用AI制作产品照片的速度提高10倍",
  },
  {
    id: "facet",
    url: "https://facet.ai/",
    img: seo.config.homeUrl + "/public/images/facet.jpg",
    type: "imageGen",
    text: "FACET",
    desc: "协作AI图像生成器和编辑专业的图像合成工具",
  },
  {
    id: "hotpot",
    url: "https://hotpot.ai/",
    img: seo.config.homeUrl + "/public/images/hotpot.jpg",
    type: "imageGen",
    text: "Hotpot",
    desc: "帮助您创建设备模型、社交媒体帖子、营销图像、应用程序图标和其他工作图形",
  },
  {
    id: "deepai",
    url: "https://deepai.org/",
    img: seo.config.homeUrl + "/public/images/deepai.jpg",
    type: "imageGen",
    text: "DeepAI",
    desc: "DeepAI提供了一套使用AI来增强创造力的工具",
  },
  {
    id: "hama",
    url: "https://www.hama.app/zh",
    img: seo.config.homeUrl + "/public/images/hama.jpg",
    type: "imageGen",
    text: "Hama",
    desc: "单个画笔擦除照片中对象的AI应用",
  },
  {
    id: "scenario",
    url: "https://www.scenario.gg/",
    img: seo.config.homeUrl + "/public/images/scenario.jpg",
    type: "imageGen",
    text: "Scenario",
    desc: "为您的游戏创建高质量、风格一致的专属资产",
  },
  {
    id: "canva",
    url: "https://www.canva.cn/?display-com-option=true",
    img: seo.config.homeUrl + "/public/images/canva.png",
    type: "imageGen|localAI",
    text: "Canva可画",
    desc: "canva旗下的在线设计工具，模板齐全",
  },
  {
    id: "ailogo",
    url: "https://ailogo.qq.com/guide/brandname",
    img: seo.config.homeUrl + "/public/images/ailogo.jpg",
    type: "imageGen|localAI",
    text: "AIDesign",
    desc: "腾讯旗下的10秒在线创建LOGO工具",
  },
  {
    id: "luban",
    url: "https://luban.aliyun.com/",
    img: seo.config.homeUrl + "/public/images/luban.jpg",
    type: "imageGen|localAI",
    text: "鹿班",
    desc: "阿里云旗下的在线设计平台",
  },
  {
    id: "ling",
    url: "https://ling.jd.com/",
    img: seo.config.homeUrl + "/public/images/ling.jpg",
    type: "imageGen|localAI",
    text: "羚珑",
    desc: "京东旗下的在线设计工具",
  },
  {
    id: "flagstudio",
    url: "https://flagstudio.baai.ac.cn/",
    img: seo.config.homeUrl + "/public/images/flagstudio.jpg",
    type: "imageGen|localAI",
    text: "FlagStudio",
    desc: "AI 文图生成艺术项目 一句话就是一幅画",
  },
  {
    id: "tiamat",
    url: "https://www.tiamat.world/",
    img: seo.config.homeUrl + "/public/images/tiamat.jpg",
    type: "imageGen|localAI",
    text: "Tiamat",
    desc: "AI艺术画生成",
  },
  {
    id: "rightbrain",
    url: "https://rightbrain.art/",
    img: seo.config.homeUrl + "/public/images/rightbrain.jpg",
    type: "imageGen|localAI",
    text: "Vega AI 创作平台",
    desc: "文生图、图生图、条件生图、姿势生图在线工具",
  },
  {
    id: "wondershareaigc",
    url: "https://aigc.wondershare.cn/",
    img: seo.config.homeUrl + "/public/images/wondershareaigc.jpg",
    type: "imageGen|localAI",
    text: "万兴爱画",
    desc: "万兴科技旗下的AIGC艺术创意灵感平台",
  },
  {
    id: "wujieai",
    url: "https://www.wujieai.com/",
    img: seo.config.homeUrl + "/public/images/wujieai.jpg",
    type: "imageGen|localAI",
    text: "无界AI",
    desc: "提供大量的AI画作精绘，可搜索参考",
  },
  {
    id: "yijiankoutu",
    url: "https://www.yijiankoutu.com/",
    img: seo.config.homeUrl + "/public/images/yijiankoutu.jpg",
    type: "imageGen|localAI",
    text: "一键抠图",
    desc: "抠图换背景、人像抠图、物品抠图、智能证件照",
  },
  {
    id: "picup",
    url: "https://www.picup.shop/",
    img: seo.config.homeUrl + "/public/images/picup.png",
    type: "imageGen|localAI",
    text: "Picup.AI皮卡智能",
    desc: "包括设计行业、国内电商、跨境 电商、证件照形象照处理APP、表情制作小程序开发等",
  },
  {
    id: "yunxiu",
    url: "https://yunxiu.meitu.com/home",
    img: seo.config.homeUrl + "/public/images/yunxiu.jpg",
    type: "imageGen|localAI",
    text: "美图云修",
    desc: "美图旗下的一站式AI修图解决方案",
  },
  {
    id: "logosc",
    url: "https://www.logosc.cn/",
    img: seo.config.homeUrl + "/public/images/logosc.jpg",
    type: "imageGen|localAI",
    text: "标小智",
    desc: "快速在线设计公司logo，生成企业VI，打造属于你的个性品牌",
  },
  {
    id: "faceRestoration",
    url: "https://arc.tencent.com/zh/ai-demos/faceRestoration",
    img: seo.config.homeUrl + "/public/images/faceRestoration.jpg",
    type: "imageGen|localAI",
    text: "ARC",
    desc: "腾讯旗下的人像修复、人像抠图、动漫增强",
  },
  {
    id: "yige",
    url: "https://yige.baidu.com/",
    img: seo.config.homeUrl + "/public/images/yige.jpg",
    type: "imageGen|localAI",
    text: "文心一格",
    desc: "百度旗下的AI艺术和创意辅助平台",
  },
  {
    id: "aiart",
    url: "https://aiart.chuangkit.com/landingpage",
    img: seo.config.homeUrl + "/public/images/aiart.jpg",
    type: "imageGen|localAI",
    text: "AI画匠",
    desc: "丹青妙笔借得AI力，万里江山任你表画中",
  },
  {
    id: "skypaint",
    url: "https://sky-paint.singularity-ai.com/index.html#/",
    img: seo.config.homeUrl + "/public/images/skypaint.jpg",
    type: "imageGen|localAI",
    text: "SKY PAINT天工巧绘",
    desc: "奇点智源(2023年昆仑万维1.6亿美元收购)的文生图平台",
  },
  {
    id: "gaodingkoutu",
    url: "https://www.gaoding.com/koutu",
    img: seo.config.homeUrl + "/public/images/gaoding.jpg",
    type: "imageGen|localAI",
    text: "稿定",
    desc: "在线删除图片背景，批量抠商品、批量抠人像、证件照换底色",
  },
  {
    id: "cutoutmeitu",
    url: "https://cutout.meitu.com/",
    img: seo.config.homeUrl + "/public/images/cutoutmeitu.jpg",
    type: "imageGen|localAI",
    text: "美图抠图",
    desc: "3s一键抠图，快速去除背景",
  },
  {
    id: "aiimagegenerator",
    url: "https://www.canva.com/ai-image-generator/",
    img: seo.config.homeUrl + "/public/images/canva.png",
    type: "imageGen|localAI",
    text: "Free Online AI Image Generator",
    desc: "canva旗下出海的AI文生图工具",
  },
  {
    id: "mobvoipaint",
    url: "https://paint.mobvoi.com/",
    img: seo.config.homeUrl + "/public/images/mobvoi.jpg",
    type: "imageGen|localAI",
    text: "言之画",
    desc: "AI图像内容创作平台",
  },
  {
    id: "imglogosc",
    url: "https://img.logosc.cn/",
    img: seo.config.homeUrl + "/public/images/imglogosc.jpg",
    type: "imageGen|localAI",
    text: "改图神器",
    desc: "在线修改图片颜色尺寸；智能抠图去水印",
  },
  {
    id: "photosir",
    url: "https://www.photosir.com/#/PhotoSir",
    img: seo.config.homeUrl + "/public/images/photosir.jpg",
    type: "imageGen|localAI",
    text: "悟空图像 Photosir",
    desc: "一款可以替代AdobePhotoShop的专业图像处理软件，采用全新的设计理念和人工智能算法",
  },
  {
    id: "bigjpg",
    url: "https://bigjpg.com/",
    img: seo.config.homeUrl + "/public/images/bigjpg.jpg",
    type: "imageGen|localAI",
    text: "BigJPG",
    desc: "AI人工智能图片放大",
  },
  {
    id: "shutterstock",
    url: "https://www.shutterstock.com/zh/ai-image-generator",
    img: seo.config.homeUrl + "/public/images/shutterstock.jpg",
    type: "imageGen|musicGen|videoGen",
    text: "Shutterstock",
    desc: "AI生成包括图像、视频和音乐",
  },
  {
    id: "hotpotrb",
    url: "https://hotpot.ai/remove-background",
    img: seo.config.homeUrl + "/public/images/hotpot.jpg",
    type: "imageGen|textGen",
    text: "Hotpot",
    desc: "AI创作者助手，帮助你创作图形、图片和文案",
  },
  {
    id: "booltool",
    url: "https://booltool.boolv.tech/home",
    img: seo.config.homeUrl + "/public/images/booltool.jpg",
    type: "imageGen|textGen|videoGen",
    text: "Booltool",
    desc: "精彩的图片、精彩的视频和精彩的文字都触手可及",
  },
  {
    id: "piggy",
    url: "https://piggy.to/magic?magic_intent=quiz",
    img: seo.config.homeUrl + "/public/images/piggy.png",
    type: "imageGen|videoGen",
    text: "Piggy",
    desc: "输入简短文本, 获得可以下载为视频或图像的故事",
  },
  {
    id: "promeai",
    url: "https://www.promeai.com/zh-CN",
    img: seo.config.homeUrl + "/public/images/promeai.jpg",
    type: "imageGen|videoGen",
    text: "神采PromeAI",
    desc: "图形、视频和动画生成，建筑师、室内设计师、产品设计师和游戏动漫设计师的必备工具",
  },
  {
    id: "llama",
    url: "https://github.com/facebookresearch/llama",
    img: seo.config.homeUrl + "/public/images/llama.jpg",
    type: "LLM",
    text: "LLaMA",
    desc: "Meta(Facebook)的大模型",
  },
  {
    id: "deepspeed",
    url: "https://www.deepspeed.ai/",
    img: seo.config.homeUrl + "/public/images/deepspeed.png",
    type: "LLM",
    text: "DeepSpeed",
    desc: "微软开源的类似ChatGPT的预训练模型",
  },
  {
    id: "openbmb",
    url: "https://www.openbmb.org/home",
    img: seo.config.homeUrl + "/public/images/openbmb.png",
    type: "LLM",
    text: "OpenBMB",
    desc: "大模型预训练",
  },
  {
    id: "SAM",
    url: "https://segment-anything.com/",
    img: seo.config.homeUrl + "/public/images/SAM.jpg",
    type: "LLM",
    text: "Segment Anything Model(SAM)",
    desc: "Meta的AI图像分割模型",
  },
  {
    id: "runwaymlGen2",
    url: "https://research.runwayml.com/gen2",
    img: seo.config.homeUrl + "/public/images/runwayml.jpg",
    type: "LLM",
    text: "Gen-2",
    desc: "Runway的能够结合文字、图像或视频片段生成视频的大模型",
  },
  {
    id: "StableLM",
    url: "https://github.com/Stability-AI/StableLM",
    img: seo.config.homeUrl + "/public/images/StableLM.png",
    type: "LLM",
    text: "StableLM",
    desc: "Stability-AI的大模型",
  },
  {
    id: "lamini",
    url: "https://www.lamini.ai/",
    img: seo.config.homeUrl + "/public/images/lamini.png",
    type: "LLM",
    text: "Lamini",
    desc: "供每个开发者构建定制、私有模型的LLM平台",
  },
  {
    id: "bloom",
    url: "https://huggingface.co/docs/transformers/model_doc/bloom",
    img: seo.config.homeUrl + "/public/images/bloom.png",
    type: "LLM",
    text: "Bloom",
    desc: "Hugging Face的语言大模型",
  },
  {
    id: "dalle2",
    url: "https://openai.com/dall-e-2",
    img: seo.config.homeUrl + "/public/images/dalle2.png",
    type: "LLM",
    text: "DALL·E·2",
    desc: "OpenAI的图像生成模型",
  },
  {
    id: "vicuna",
    url: "https://chat.lmsys.org/",
    img: seo.config.homeUrl + "/public/images/vicuna.png",
    type: "LLM",
    text: "Vicuna",
    desc: "开源的聊天大模型",
  },
  {
    id: "lightning",
    url: "https://lightning.ai/",
    img: seo.config.homeUrl + "/public/images/lightning.jpg",
    type: "LLM",
    text: "Lightning AI",
    desc: "PyTorch Lightning团队的深度学习框架",
  },
  {
    id: "lobe",
    url: "https://www.lobe.ai/",
    img: seo.config.homeUrl + "/public/images/lobe.jpg",
    type: "LLM",
    text: "Lobe",
    desc: "免费机器学习模型训练的应用",
  },
  {
    id: "scale",
    url: "https://scale.com/",
    img: seo.config.homeUrl + "/public/images/scale.jpg",
    type: "LLM",
    text: "Scale",
    desc: "机器学习平台",
  },
  {
    id: "replicate",
    url: "https://replicate.com/",
    img: seo.config.homeUrl + "/public/images/replicate.jpg",
    type: "LLM",
    text: "Replicate",
    desc: "在线开源机器学习模型",
  },
  {
    id: "evidentlyai",
    url: "https://www.evidentlyai.com/",
    img: seo.config.homeUrl + "/public/images/evidentlyai.jpg",
    type: "LLM",
    text: "Evidently AI",
    desc: "机器学习模型训练工具",
  },
  {
    id: "imagen",
    url: "https://imagen.research.google/",
    img: seo.config.homeUrl + "/public/images/google.png",
    type: "LLM",
    text: "Imagen",
    desc: "Google的文生图大模型",
  },
  {
    id: "assemblyai",
    url: "https://www.assemblyai.com/",
    img: seo.config.homeUrl + "/public/images/assemblyai.jpg",
    type: "LLM",
    text: "AssemblyAI",
    desc: "转录和分析语音的大模型",
  },
  {
    id: "paddle",
    url: "https://openi.org.cn/projects/paddle/",
    img: seo.config.homeUrl + "/public/images/paddle.png",
    type: "LLM|localAI",
    text: "Paddle",
    desc: "百度的人工智能算法框架",
  },
  {
    id: "m6",
    url: "https://m6.aliyun.com/#/",
    img: seo.config.homeUrl + "/public/images/aliyun.png",
    type: "LLM|localAI",
    text: "M6",
    desc: "阿里巴巴的语言大模型M6",
  },
  {
    id: "modelscope",
    url: "https://www.modelscope.cn/home",
    img: seo.config.homeUrl + "/public/images/modelscope.png",
    type: "LLM|localAI",
    text: "Model Scope",
    desc: "阿里达摩院的AI模型社区",
  },
  {
    id: "mobvoiapi",
    url: "https://openapi.mobvoi.com/index",
    img: seo.config.homeUrl + "/public/images/mobvoi.png",
    type: "LLM|localAI",
    text: "序列猴子",
    desc: "出门问问的语言大模型",
  },
  {
    id: "chatglm",
    url: "https://chatglm.cn/",
    img: seo.config.homeUrl + "/public/images/chatglm.png",
    type: "LLM|localAI",
    text: "ChatGLM",
    desc: "面向高校学术交流与行业合作的大模型内测申请入口",
  },
  {
    id: "moss",
    url: "https://moss.fastnlp.top/",
    img: seo.config.homeUrl + "/public/images/moss.png",
    type: "LLM|localAI",
    text: "Moss",
    desc: "复旦大学语言大模型内测申请入口",
  },
  {
    id: "xinghuo",
    url: "https://xinghuo.xfyun.cn/",
    img: seo.config.homeUrl + "/public/images/xinghuo.jpg",
    type: "LLM|localAI",
    text: "讯飞星火认知大模型",
    desc: "科大讯飞大模型内测申请入口",
  },
  {
    id: "yiyan",
    url: "https://yiyan.baidu.com/welcome",
    img: seo.config.homeUrl + "/public/images/yiyan.jpg",
    type: "LLM|localAI",
    text: "文心一言",
    desc: "百度的知识增强大语言模型",
  },
  {
    id: "tongyi",
    url: "https://tongyi.aliyun.com/",
    img: seo.config.homeUrl + "/public/images/tongyi.jpg",
    type: "LLM|localAI",
    text: "通义大模型",
    desc: "通义千问、通义万相、通义听悟内测申请入口",
  },
  {
    id: "360so",
    url: "https://www.so.com/zt/invite.html#/",
    img: seo.config.homeUrl + "/public/images/360so.jpg",
    type: "LLM|localAI",
    text: "360智脑",
    desc: "360GPT内测申请入口",
  },
  {
    id: "tiangong",
    url: "https://tiangong.kunlun.com/",
    img: seo.config.homeUrl + "/public/images/tiangong.jpg",
    type: "LLM|localAI",
    text: "天工AI助手",
    desc: "奇点智源的天工大模型内测申请入口",
  },
  {
    id: "beatoven",
    url: "https://www.beatoven.ai/",
    img: seo.config.homeUrl + "/public/images/beatoven.jpg",
    type: "musicGen",
    text: "Beatoven",
    desc: "创建可定制的免费版权音乐，让您的故事更加引人入胜。",
  },
  {
    id: "banterai",
    url: "https://banterai.app/",
    img: seo.config.homeUrl + "/public/images/banterai.png",
    type: "musicGen",
    text: "BanterAI",
    desc: "一个致力于提供人性化声音对话的人工智能平台",
  },
  {
    id: "riffusion",
    url: "https://www.riffusion.com/",
    img: seo.config.homeUrl + "/public/images/riffusion.jpg",
    type: "musicGen",
    text: "Riffusion",
    desc: "AI音乐生成",
  },
  {
    id: "uberduck",
    url: "https://uberduck.ai/",
    img: seo.config.homeUrl + "/public/images/uberduck.jpg",
    type: "musicGen",
    text: "Uberduck",
    desc: "AI音频的音乐制作网站",
  },
  {
    id: "adobepodcast",
    url: "https://podcast.adobe.com/",
    img: seo.config.homeUrl + "/public/images/adobe.jpg",
    type: "musicGen",
    text: "Adobe Podcast",
    desc: "Adobe旗下的在线AI音频编辑",
  },
  {
    id: "resemble",
    url: "https://www.resemble.ai/",
    img: seo.config.homeUrl + "/public/images/resemble.jpg",
    type: "musicGen",
    text: "RESEMBLE.AI",
    desc: "AI文本生成人声语音网站",
  },
  {
    id: "ibm",
    url: "https://www.ibm.com/products/text-to-speech",
    img: seo.config.homeUrl + "/public/images/ibm.jpg",
    type: "musicGen",
    text: "Watson Text to Speech",
    desc: "IBM的文生语音",
  },
  {
    id: "fakeyou",
    url: "https://fakeyou.com/",
    img: seo.config.homeUrl + "/public/images/fakeyou.jpg",
    type: "musicGen",
    text: "FakeYou",
    desc: "AI音乐制作、文字转语音、语音转语音网站",
  },
  {
    id: "mubert",
    url: "https://mubert.com/",
    img: seo.config.homeUrl + "/public/images/mubert.jpg",
    type: "musicGen",
    text: "Mubert",
    desc: "AI音乐创作助手",
  },
  {
    id: "beatbot",
    url: "https://beatbot.fm/",
    img: seo.config.homeUrl + "/public/images/beatbot.jpg",
    type: "musicGen",
    text: "BeatBot",
    desc: "文生语音机器人",
  },
  {
    id: "audoai",
    url: "https://audo.ai/",
    img: seo.config.homeUrl + "/public/images/audoai.jpg",
    type: "musicGen",
    text: "Audo studio",
    desc: "消除噪声、平衡声音、调节音量AI工具",
  },
  {
    id: "naturalreaders",
    url: "https://www.naturalreaders.com/",
    img: seo.config.homeUrl + "/public/images/naturalreaders.jpg",
    type: "musicGen",
    text: "NaturalReader",
    desc: "演讲、商业、教育相关AI语音生成",
  },
  {
    id: "lalal",
    url: "https://www.lalal.ai/",
    img: seo.config.homeUrl + "/public/images/lalal.jpg",
    type: "musicGen",
    text: "LALAL.AI",
    desc: "AI人声乐器分离和提取工具",
  },
  {
    id: "krisp",
    url: "https://krisp.ai/",
    img: seo.config.homeUrl + "/public/images/krisp.jpg",
    type: "musicGen",
    text: "Krisp",
    desc: "AI消除噪声工具",
  },
  {
    id: "playht",
    url: "https://play.ht/",
    img: seo.config.homeUrl + "/public/images/playht.jpg",
    type: "musicGen",
    text: "PlayHT",
    desc: "AI仿真语音生成、演讲语音生成工具",
  },
  {
    id: "murf",
    url: "https://murf.ai/",
    img: seo.config.homeUrl + "/public/images/murf.jpg",
    type: "musicGen",
    text: "MURF.AI",
    desc: "AI语音合成，各种角色都有",
  },
  {
    id: "lemonaid",
    url: "https://lemonaid.ai/",
    img: seo.config.homeUrl + "/public/images/lemonaid.jpg",
    type: "musicGen",
    text: "Lemonaid Music",
    desc: "AI音乐创作助手",
  },
  {
    id: "soundraw",
    url: "https://soundraw.io/",
    img: seo.config.homeUrl + "/public/images/soundraw.png",
    type: "musicGen",
    text: "Soundraw",
    desc: "AI创作免版税音乐",
  },
  {
    id: "boomy",
    url: "https://boomy.com/",
    img: seo.config.homeUrl + "/public/images/boomy.jpg",
    type: "musicGen",
    text: "Boomy",
    desc: "AI音乐创作助手",
  },
  {
    id: "veed",
    url: "https://www.veed.io/tools/text-to-speech-video/ai-voice-generator",
    img: seo.config.homeUrl + "/public/images/veed.jpg",
    type: "musicGen",
    text: "VEED.IO",
    desc: "VEED旗下的AI语音生成",
  },
  {
    id: "clipchamp",
    url: "https://clipchamp.com/zh-hans/features/ai-voice-over-generator/",
    img: seo.config.homeUrl + "/public/images/clipchamp.jpg",
    type: "musicGen",
    text: "Clipchamp",
    desc: "文字转语音生成器",
  },
  {
    id: "themetavoice",
    url: "https://themetavoice.xyz/",
    img: seo.config.homeUrl + "/public/images/themetavoice.jpg",
    type: "musicGen",
    text: "MetaVoice",
    desc: "AI语音生成网站",
  },
  {
    id: "speechify",
    url: "https://speechify.com/zh-hans/?landing_url=https%3A%2F%2Fspeechify.com%2Fzh-hans%2F",
    img: seo.config.homeUrl + "/public/images/speechify.jpg",
    type: "musicGen",
    text: "Speechify",
    desc: "文字转语音朗读软件",
  },
  {
    id: "voicemaker",
    url: "https://voicemaker.in/",
    img: seo.config.homeUrl + "/public/images/voicemaker.jpg",
    type: "musicGen",
    text: "Voicemaker",
    desc: "在线AI文字转语音",
  },
  {
    id: "voiceai",
    url: "https://voice.ai/",
    img: seo.config.homeUrl + "/public/images/voiceai.jpg",
    type: "musicGen",
    text: "Voice.ai",
    desc: "AI文字转语音平台",
  },
  {
    id: "listnr",
    url: "https://www.listnr.tech/",
    img: seo.config.homeUrl + "/public/images/listnr.jpg",
    type: "musicGen",
    text: "Listnr",
    desc: "AI文字转语音，MP3和WAV格式可下载",
  },
  {
    id: "voicemod",
    url: "https://www.voicemod.net/ai-voices",
    img: seo.config.homeUrl + "/public/images/voicemod.jpg",
    type: "musicGen",
    text: "Voicemod",
    desc: "AI变声工具",
  },
  {
    id: "wellsaidlabs",
    url: "https://wellsaidlabs.com/",
    img: seo.config.homeUrl + "/public/images/wellsaidlabs.jpg",
    type: "musicGen",
    text: "WellSaid",
    desc: "AI语音快速生成",
  },
  {
    id: "notta",
    url: "https://www.notta.ai/en",
    img: seo.config.homeUrl + "/public/images/notta.jpg",
    type: "musicGen",
    text: "Notta",
    desc: "日本制造语音生成器",
  },
  {
    id: "tianyin",
    url: "https://tianyin.music.163.com/#/",
    img: seo.config.homeUrl + "/public/images/tianyin.jpg",
    type: "musicGen|localAI",
    text: "网易天音",
    desc: "一站式AI音乐创作工具",
  },
  {
    id: "tme_studio",
    url: "https://y.qq.com/tme_studio/index.html#",
    img: seo.config.homeUrl + "/public/images/tme_studio.jpg",
    type: "musicGen|localAI",
    text: "TME Studio",
    desc: "QQ音乐AI音乐创作助手",
  },
  {
    id: "peiyin",
    url: "https://peiyin.xunfei.cn/",
    img: seo.config.homeUrl + "/public/images/peiyin.jpg",
    type: "musicGen|localAI",
    text: "讯飞智作",
    desc: "科大讯飞旗下提供文字转语音、语音合成",
  },
  {
    id: "moyin",
    url: "https://www.moyin.com/",
    img: seo.config.homeUrl + "/public/images/moyin.jpg",
    type: "musicGen|localAI",
    text: "魔音工坊",
    desc: "出门问问旗下的AI配音平台",
  },
  {
    id: "bgmcat",
    url: "https://bgmcat.com/home",
    img: seo.config.homeUrl + "/public/images/bgmcat.jpg",
    type: "musicGen|localAI",
    text: "BGM猫",
    desc: "AI一键生成视频/播客配乐和片头音乐",
  },
  {
    id: "lovo",
    url: "https://lovo.ai/",
    img: seo.config.homeUrl + "/public/images/lovo.jpg",
    type: "musicGen|textGen",
    text: "LOVO",
    desc: "AI声音生成，文字生成演讲",
  },
  {
    id: "yizhentv",
    url: "https://aigc.yizhentv.com/",
    img: seo.config.homeUrl + "/public/images/yizhentv.jpg",
    type: "musicGen|textGen|imageGen|videoGen|localAI",
    text: "一帧秒创",
    desc: "提供文字续写、文字转语音、文生图、图文转视频等创作服务",
  },
  {
    id: "presentations",
    url: "https://www.presentations.ai/",
    img: seo.config.homeUrl + "/public/images/presentations.jpg",
    type: "officeAssistant",
    text: "Presentations.AI",
    desc: "引入ChatGPT的演示文档",
  },
  {
    id: "rows",
    url: "https://rows.com/",
    img: seo.config.homeUrl + "/public/images/rows.png",
    type: "officeAssistant",
    text: "Rows",
    desc: "AI在线表格处理工具",
  },
  {
    id: "vistasocial",
    url: "https://vistasocial.com/",
    img: seo.config.homeUrl + "/public/images/vistasocial.png",
    type: "officeAssistant",
    text: "Vista Social",
    desc: "精心打造的社交媒体管理工具，帮助您超越目标, 现代化的社交媒体管理工具，适用于所有人",
  },
  {
    id: "ailawyer",
    url: "https://ailawyer.pro/",
    img: seo.config.homeUrl + "/public/images/ailawyer.png",
    type: "officeAssistant",
    text: "AI Lawyer",
    desc: "告别昂贵的咨询费用、长时间等待预约和晦涩难懂的法律文件。AI律师随时随地为您提供专业的法律帮助。",
  },
  {
    id: "axonjay",
    url: "https://www.axonjay.ai/",
    img: seo.config.homeUrl + "/public/images/axonjay.jpg",
    type: "officeAssistant",
    text: "AxonJay",
    desc: "实时揭示和预测企业的真实行为, 是一款开创性和独特的自我机器学习平台。",
  },
  {
    id: "durable",
    url: "https://durable.co/",
    img: seo.config.homeUrl + "/public/images/durable.png",
    type: "officeAssistant",
    text: "Durable",
    desc: "使用人工智能, 只需30秒, 构建一个网站",
  },
  {
    id: "namelix",
    url: "https://namelix.com/",
    img: seo.config.homeUrl + "/public/images/namelix.png",
    type: "officeAssistant",
    text: "Namelix",
    desc: "公司起名器, 使用人工智能生成一个短小易记的商业名称",
  },
  {
    id: "otter",
    url: "https://otter.ai/",
    img: seo.config.homeUrl + "/public/images/otter.jpg",
    type: "officeAssistant",
    text: "Otter",
    desc: "AI会议内容生成以及实时转录工具",
  },
  {
    id: "fireflies",
    url: "https://fireflies.ai/",
    img: seo.config.homeUrl + "/public/images/fireflies.jpg",
    type: "officeAssistant",
    text: "fireflies.ai",
    desc: "AI会议辅助生成工具",
  },
  {
    id: "ultimate",
    url: "https://www.ayoa.com/ultimate/",
    img: seo.config.homeUrl + "/public/images/ultimate.jpg",
    type: "officeAssistant",
    text: "Ayoa Ultimate",
    desc: "AI思维导图工具",
  },
  {
    id: "miro",
    url: "https://miro.com/mind-map/",
    img: seo.config.homeUrl + "/public/images/miro.jpg",
    type: "officeAssistant",
    text: "Miro AI",
    desc: "在线思维导图制作工具",
  },
  {
    id: "taskade",
    url: "https://www.taskade.com/",
    img: seo.config.homeUrl + "/public/images/taskade.jpg",
    type: "officeAssistant",
    text: "Taskade",
    desc: "AI大纲和思维导图制作工具",
  },
  {
    id: "amymind",
    url: "https://amymind.com/",
    img: seo.config.homeUrl + "/public/images/amymind.png",
    type: "officeAssistant",
    text: "Amy Mind",
    desc: "伴你思考的AI思维导图工具",
  },
  {
    id: "whimsical",
    url: "https://whimsical.com/ai-mind-maps",
    img: seo.config.homeUrl + "/public/images/whimsical.jpg",
    type: "officeAssistant",
    text: "Whimsical",
    desc: "AI思维导图工具",
  },
  {
    id: "super",
    url: "https://super.ai/",
    img: seo.config.homeUrl + "/public/images/super.jpg",
    type: "officeAssistant",
    text: "super.ai",
    desc: "AI文档处理工具",
  },
  {
    id: "rossum",
    url: "https://rossum.ai/",
    img: seo.config.homeUrl + "/public/images/rossum.jpg",
    type: "officeAssistant",
    text: "rossum",
    desc: "AI文档处理工具",
  },
  {
    id: "pandagpt",
    url: "https://www.pandagpt.io/",
    img: seo.config.homeUrl + "/public/images/pandagpt.jpg",
    type: "officeAssistant",
    text: "PandaGPT",
    desc: "AI文档总结工具",
  },
  {
    id: "chatdoc",
    url: "https://chatdoc.com/",
    img: seo.config.homeUrl + "/public/images/chatdoc.jpg",
    type: "officeAssistant",
    text: "ChatDOC",
    desc: "AI文档处理助手",
  },
  {
    id: "humata",
    url: "https://www.humata.ai/",
    img: seo.config.homeUrl + "/public/images/humata.jpg",
    type: "officeAssistant",
    text: "Humata",
    desc: "AI文档处理助手",
  },
  {
    id: "beautiful",
    url: "https://www.beautiful.ai/",
    img: seo.config.homeUrl + "/public/images/beautiful.jpg",
    type: "officeAssistant",
    text: "beautiful.ai",
    desc: "AI展示幻灯片生成工具",
  },
  {
    id: "chronicle",
    url: "https://chroniclehq.com/",
    img: seo.config.homeUrl + "/public/images/chronicle.jpg",
    type: "officeAssistant",
    text: "Chronicle",
    desc: "AI演示文稿制作工具",
  },
  {
    id: "slidesai",
    url: "https://www.slidesai.io/",
    img: seo.config.homeUrl + "/public/images/slidesai.png",
    type: "officeAssistant",
    text: "Slides AI",
    desc: "AI生成幻灯片工具",
  },
  {
    id: "auxi",
    url: "https://www.auxi.ai/",
    img: seo.config.homeUrl + "/public/images/auxi.jpg",
    type: "officeAssistant",
    text: "Auxi",
    desc: "PPT的AI插件",
  },
  {
    id: "formx",
    url: "https://www.formx.ai/",
    img: seo.config.homeUrl + "/public/images/formx.jpg",
    type: "officeAssistant",
    text: "FormX.ai",
    desc: "AI表格和文档提取数据工具",
  },
  {
    id: "formularizer",
    url: "https://formularizer.com/",
    img: seo.config.homeUrl + "/public/images/formularizer.jpg",
    type: "officeAssistant",
    text: "Excel Formularizer",
    desc: "AI文本转Excel公式工具",
  },
  {
    id: "sheetgod",
    url: "https://www.boloforms.com/sheetgod/",
    img: seo.config.homeUrl + "/public/images/sheetgod.jpg",
    type: "officeAssistant",
    text: "SheetGod",
    desc: "AI Excel公式工具",
  },
  {
    id: "excelly",
    url: "https://excelly-ai.io/index.html",
    img: seo.config.homeUrl + "/public/images/excelly.jpg",
    type: "officeAssistant",
    text: "Excelly-AI",
    desc: "文本转Excel公式工具",
  },
  {
    id: "sheetplus",
    url: "https://sheetplus.ai/",
    img: seo.config.homeUrl + "/public/images/sheetplus.jpg",
    type: "officeAssistant",
    text: "Sheet+",
    desc: "AI表格处理工具",
  },
  {
    id: "airgram",
    url: "https://www.airgram.io/",
    img: seo.config.homeUrl + "/public/images/airgram.jpg",
    type: "officeAssistant",
    text: "Airgram",
    desc: "AI会议笔记和总结工具",
  },
  {
    id: "timelyapp",
    url: "https://timelyapp.com/",
    img: seo.config.homeUrl + "/public/images/timelyapp.jpg",
    type: "officeAssistant",
    text: "Timely",
    desc: "AI驱动的时间管理应用",
  },
  {
    id: "noty",
    url: "https://noty.ai/",
    img: seo.config.homeUrl + "/public/images/noty.jpg",
    type: "officeAssistant",
    text: "Noty.ai",
    desc: "AI会议助手",
  },
  {
    id: "forefront",
    url: "https://www.forefront.ai/",
    img: seo.config.homeUrl + "/public/images/forefront.jpg",
    type: "officeAssistant",
    text: "Forefront",
    desc: "AI工作助手",
  },
  {
    id: "gamma",
    url: "https://gamma.app/",
    img: seo.config.homeUrl + "/public/images/gamma.jpg",
    type: "officeAssistant",
    text: "Gamma App",
    desc: "AI 演示文稿",
  },
  {
    id: "decktopus",
    url: "https://www.decktopus.com/",
    img: seo.config.homeUrl + "/public/images/decktopus.jpg",
    type: "officeAssistant",
    text: "Decktopus AI",
    desc: "AI 演示文稿工具",
  },
  {
    id: "tome",
    url: "https://tome.app/",
    img: seo.config.homeUrl + "/public/images/tome.jpg",
    type: "officeAssistant",
    text: "Tome AI",
    desc: "AI PDF生成工具",
  },
  {
    id: "yinxiang",
    url: "https://www.yinxiang.com/about/yxai-yxbj/",
    img: seo.config.homeUrl + "/public/images/yinxiang.jpg",
    type: "officeAssistant|eduAssistant|localAI",
    text: "印象AI",
    desc: "AI知识管理",
  },
  {
    id: "52cv",
    url: "https://www.52cv.com/",
    img: seo.config.homeUrl + "/public/images/52cv.png",
    type: "officeAssistant|eduAssistant|localAI",
    text: "职徒简历",
    desc: "AI简历制作网站",
  },
  {
    id: "eva",
    url: "https://colors.eva.design/",
    img: seo.config.homeUrl + "/public/images/eva.jpg",
    type: "officeAssistant|imageGen",
    text: "Eva Design System",
    desc: "AI色板生成工具",
  },
  {
    id: "colorwheel",
    url: "https://brandmark.io/color-wheel/",
    img: seo.config.homeUrl + "/public/images/colorwheel.png",
    type: "officeAssistant|imageGen",
    text: "AI Color Wheel",
    desc: "图标、插图上色AI工具",
  },
  {
    id: "huemint",
    url: "https://huemint.com/",
    img: seo.config.homeUrl + "/public/images/huemint.jpg",
    type: "officeAssistant|imageGen",
    text: "Huemint",
    desc: "图标调色AI工具",
  },
  {
    id: "logomaster",
    url: "https://logomaster.ai/",
    img: seo.config.homeUrl + "/public/images/logomaster.jpg",
    type: "officeAssistant|imageGen",
    text: "Logomaster.ai",
    desc: "AI图标设计",
  },
  {
    id: "magician",
    url: "https://magician.design/",
    img: seo.config.homeUrl + "/public/images/magician.jpg",
    type: "officeAssistant|imageGen",
    text: "Magician",
    desc: "Figma的AI图标插件",
  },
  {
    id: "appicons",
    url: "https://appicons.ai/",
    img: seo.config.homeUrl + "/public/images/appicons.jpg",
    type: "officeAssistant|imageGen",
    text: "Appicons AI",
    desc: "AI生成应用图标工具",
  },
  {
    id: "minutes",
    url: "https://www.feishu.cn/product/minutes",
    img: seo.config.homeUrl + "/public/images/minutes.png",
    type: "officeAssistant|localAI",
    text: "飞书妙记",
    desc: "语音识别转文字工具",
  },
  {
    id: "iflyrec",
    url: "https://meeting.iflyrec.com/",
    img: seo.config.homeUrl + "/public/images/iflyrec.jpg",
    type: "officeAssistant|localAI",
    text: "讯飞听见",
    desc: "AI智能会议系统",
  },
  {
    id: "tingwu",
    url: "https://tingwu.aliyun.com/",
    img: seo.config.homeUrl + "/public/images/tingwu.png",
    type: "officeAssistant|localAI",
    text: "听悟",
    desc: "AI会议助手",
  },
  {
    id: "boardmix",
    url: "https://boardmix.cn/ai-whiteboard/",
    img: seo.config.homeUrl + "/public/images/boardmix.jpg",
    type: "officeAssistant|localAI",
    text: "博思AI白板",
    desc: "万兴科技旗下AI自动生成思维导图工具",
  },
  {
    id: "gitmind",
    url: "https://gitmind.cn/?ref=ai-bot.cn",
    img: seo.config.homeUrl + "/public/images/gitmind.png",
    type: "officeAssistant|localAI",
    text: "GitMind思乎AI",
    desc: "AI驱动的思维导图工具",
  },
  {
    id: "shutu",
    url: "https://shutu.cn/",
    img: seo.config.homeUrl + "/public/images/shutu.jpg",
    type: "officeAssistant|localAI",
    text: "TreeMind树图",
    desc: "AI思维导图工具",
  },
  {
    id: "xmind",
    url: "https://xmind.ai/",
    img: seo.config.homeUrl + "/public/images/xmind.png",
    type: "officeAssistant|localAI",
    text: "Xmind Copilot",
    desc: "结合GPT的Xmind思维导图",
  },
  {
    id: "mindshow",
    url: "https://www.mindshow.fun/#/home",
    img: seo.config.homeUrl + "/public/images/mindshow.jpg",
    type: "officeAssistant|localAI",
    text: "MindShow",
    desc: "AI生成PPT网站",
  },
  {
    id: "caixuan",
    url: "https://app.caixuan.cc/create/",
    img: seo.config.homeUrl + "/public/images/caixuan.jpg",
    type: "officeAssistant|localAI",
    text: "歌者AI",
    desc: "AI制作PPT工具",
  },
  {
    id: "chat-ppt",
    url: "https://chat-ppt.com/",
    img: seo.config.homeUrl + "/public/images/chat-ppt.jpg",
    type: "officeAssistant|localAI",
    text: "ChatPPT",
    desc: "命令式一键生成PPT",
  },
  {
    id: "meitu",
    url: "https://design.meitu.com/ppt/",
    img: seo.config.homeUrl + "/public/images/meitu.jpg",
    type: "officeAssistant|localAI",
    text: "美图AI PPT",
    desc: "一句话，10秒生成PPT",
  },
  {
    id: "kuaiyi",
    url: "https://kuaiyi.wps.cn/txt-translate",
    img: seo.config.homeUrl + "/public/images/kuaiyi.jpg",
    type: "officeAssistant|textGen|eduAssistant|localAI",
    text: "金山快译",
    desc: "WPS翻译",
  },
  {
    id: "cpg",
    url: "https://github.com/benf2004/ChatGPT-Prompt-Genius",
    img: seo.config.homeUrl + "/public/images/cpg.png",
    type: "promptTool",
    text: "ChatGPT Prompt Genius",
    desc: "Prompt浏览器扩展",
  },
  {
    id: "promptbase",
    url: "https://promptbase.com/",
    img: seo.config.homeUrl + "/public/images/promptbase.jpg",
    type: "promptTool",
    text: "PromptBase",
    desc: "Prompt交易平台，包括：DALL·E, GPT, Midjourney, Stable Diffusion, ChatGPT",
  },
  {
    id: "promptvine",
    url: "https://promptvine.com/",
    img: seo.config.homeUrl + "/public/images/promptvine.png",
    type: "promptTool",
    text: "PromptVine",
    desc: "提供ChatGPT的Prompts",
  },
  {
    id: "aweprompts",
    url: "https://prompts.chat/",
    img: seo.config.homeUrl + "/public/images/aweprompts.png",
    type: "promptTool",
    text: "Awesome ChatGPT Prompts",
    desc: "提供ChatGPT相关的Prompts",
  },
  {
    id: "learningprompt",
    url: "https://learningprompt.wiki/",
    img: seo.config.homeUrl + "/public/images/learningprompt.jpg",
    type: "promptTool",
    text: "Learning Prompt",
    desc: "提供提示词教程",
  },
  {
    id: "aishort",
    url: "https://www.aishort.top/",
    img: seo.config.homeUrl + "/public/images/aishort.png",
    type: "promptTool",
    text: "ChatGPT 快捷指令",
    desc: "收集了大量的提示词案例",
  },
  {
    id: "noonshot",
    url: "https://prompt.noonshot.com/",
    img: seo.config.homeUrl + "/public/images/noonshot.jpg",
    type: "promptTool",
    text: "MidJourney Prompt Tool",
    desc: "MidJourney Prompt Helper",
  },
  {
    id: "snackprompt",
    url: "https://snackprompt.com/",
    img: seo.config.homeUrl + "/public/images/snackprompt.jpg",
    type: "promptTool",
    text: "Snack Prompt",
    desc: "ChatGPT提示词(英文)",
  },
  {
    id: "publicprompts",
    url: "https://publicprompts.art/",
    img: seo.config.homeUrl + "/public/images/publicprompts.jpg",
    type: "promptTool",
    text: "Public Prompts",
    desc: "StableDiffusion的Prompts",
  },
  {
    id: "generrated",
    url: "https://generrated.com/",
    img: seo.config.homeUrl + "/public/images/generrated.png",
    type: "promptTool",
    text: "Generrated",
    desc: "DALL·E 2的图片生成指令语句",
  },
  {
    id: "prompthero",
    url: "https://prompthero.com/",
    img: seo.config.homeUrl + "/public/images/prompthero.jpg",
    type: "promptTool",
    text: "Prompt Hero",
    desc: "SD、MJ、ChatGPT提示词搜索引擎",
  },
  {
    id: "openart",
    url: "https://openart.ai/promptbook",
    img: seo.config.homeUrl + "/public/images/openart.jpg",
    type: "promptTool",
    text: "Open Art",
    desc: "10M+ prompts",
  },
  {
    id: "flowgpt",
    url: "https://flowgpt.com/",
    img: seo.config.homeUrl + "/public/images/flowgpt.jpg",
    type: "promptTool",
    text: "FlowGPT",
    desc: "ChatGPT提示词收录网站",
  },
  {
    id: "icihun",
    url: "https://icihun.com/",
    img: seo.config.homeUrl + "/public/images/icihun.png",
    type: "promptTool|localAI",
    text: "词魂",
    desc: "每日更新1000+高质量提示词",
  },
  {
    id: "clickprompt",
    url: "https://www.clickprompt.org/zh-CN/",
    img: seo.config.homeUrl + "/public/images/clickprompt.jpg",
    type: "promptTool|localAI",
    text: "Click Prompt",
    desc: "Prompt编写工具",
  },
  {
    id: "aiprm",
    url: "https://www.aiprm.com/",
    img: seo.config.homeUrl + "/public/images/aiprm.jpg",
    type: "textGen",
    text: "AIPRM",
    desc: "文案写作的Chrome浏览器扩展",
  },
  {
    id: "gowinston",
    url: "https://gowinston.ai/",
    img: seo.config.homeUrl + "/public/images/gowinston.jpg",
    type: "textGen",
    text: "Winston AI",
    desc: "基于AI的内容检测解决方案",
  },
  {
    id: "copyleaks",
    url: "https://copyleaks.com/",
    img: seo.config.homeUrl + "/public/images/copyleaks.jpg",
    type: "textGen",
    text: "Copyleaks",
    desc: "AI文本分析润色工具",
  },
  {
    id: "originality",
    url: "https://originality.ai/",
    img: seo.config.homeUrl + "/public/images/originality.jpg",
    type: "textGen",
    text: "Originality.ai",
    desc: "AI文本防抄袭检测工具",
  },
  {
    id: "writer",
    url: "https://writer.com/ai-content-detector/",
    img: seo.config.homeUrl + "/public/images/writer.jpg",
    type: "textGen",
    text: "Writer",
    desc: "文本内容检测工具，讨好搜索引擎",
  },
  {
    id: "writefull",
    url: "https://x.writefull.com/gpt-detector",
    img: seo.config.homeUrl + "/public/images/writefull.jpg",
    type: "textGen",
    text: "Writefull",
    desc: "检测文本内容是原创还是AI生成的网站",
  },
  {
    id: "sapling",
    url: "https://sapling.ai/ai-content-detector",
    img: seo.config.homeUrl + "/public/images/sapling.jpg",
    type: "textGen",
    text: "Sapling",
    desc: "检测文本由AI工具生成的网站",
  },
  {
    id: "smodin",
    url: "https://smodin.io/ai-content-detector",
    img: seo.config.homeUrl + "/public/images/smodin.jpg",
    type: "textGen",
    text: "Smodin",
    desc: "检测文本(支持多语言)由AI工具生成的网站",
  },
  {
    id: "writecream",
    url: "https://www.writecream.com/ai-content-detector/",
    img: seo.config.homeUrl + "/public/images/writecream.jpg",
    type: "textGen",
    text: "Writecream",
    desc: "AI写作，生成图片、音频大的网站",
  },
  {
    id: "aiseo",
    url: "https://aiseo.ai/tools/ai-content-detector.html",
    img: seo.config.homeUrl + "/public/images/aiseo.jpg",
    type: "textGen",
    text: "AISEO",
    desc: "AI伪原创写作助手",
  },
  {
    id: "checkforai",
    url: "https://checkforai.com/",
    img: seo.config.homeUrl + "/public/images/checkforai.jpg",
    type: "textGen",
    text: "CheckforAi",
    desc: "非盈利的AI内容检测网站",
  },
  {
    id: "gptzero",
    url: "https://gptzero.me/",
    img: seo.config.homeUrl + "/public/images/gptzero.jpg",
    type: "textGen",
    text: "GPTZero",
    desc: "AI文本检测工具",
  },
  {
    id: "jasper",
    url: "https://www.jasper.ai/chat",
    img: seo.config.homeUrl + "/public/images/jasper.jpg",
    type: "textGen",
    text: "Jasper",
    desc: "Jasper的AI写作助手",
  },
  {
    id: "gomoonbeam",
    url: "https://www.gomoonbeam.com/",
    img: seo.config.homeUrl + "/public/images/gomoonbeam.png",
    type: "textGen",
    text: "Moonbeam",
    desc: "您的长篇写作人工智能助手",
  },
  {
    id: "peppercontent",
    url: "https://www.peppercontent.io/peppertype-ai/",
    img: seo.config.homeUrl + "/public/images/peppercontent.jpg",
    type: "textGen",
    text: "Peppertype.ai",
    desc: "构思、创建、分发和衡量您的内容",
  },
  {
    id: "spell",
    url: "https://spell.tools/",
    img: seo.config.homeUrl + "/public/images/spell.png",
    type: "textGen",
    text: "Spell",
    desc: "让AI编写您的内容,省时省力",
  },
  {
    id: "hyperwriteai",
    url: "https://hyperwriteai.com/",
    img: seo.config.homeUrl + "/public/images/hyperwriteai.jpg",
    type: "textGen",
    text: "HyperWrite",
    desc: "你的私人AI写作助手",
  },
  {
    id: "typeface",
    url: "https://www.typeface.ai/",
    img: seo.config.homeUrl + "/public/images/typeface.jpg",
    type: "textGen",
    text: "Typeface",
    desc: "企业级生成式人工智能应用程序，让每个人都能表达自己独特的想象力",
  },
  {
    id: "contentbot",
    url: "https://contentbot.ai/",
    img: seo.config.homeUrl + "/public/images/contentbot.jpg",
    type: "textGen",
    text: "ContentBot.ai",
    desc: "创建自定义AI内容工作流程，通过导入运行，或者简单地使用我们的AI Writer",
  },
  {
    id: "compose",
    url: "https://www.compose.ai/",
    img: seo.config.homeUrl + "/public/images/compose.jpg",
    type: "textGen",
    text: "Compose AI",
    desc: "谷歌插件AI自动生成、文本生成，减少40%耗时",
  },
  {
    id: "texta",
    url: "https://texta.ai/",
    img: seo.config.homeUrl + "/public/images/texta.png",
    type: "textGen",
    text: "Texta",
    desc: "只需单击1次即可获得完美的博客文章",
  },
  {
    id: "sudowrite",
    url: "https://www.sudowrite.com/",
    img: seo.config.homeUrl + "/public/images/sudowrite.jpg",
    type: "textGen",
    text: "Sudowrite",
    desc: "AI写作助手",
  },
  {
    id: "closerscopy",
    url: "https://www.closerscopy.com/",
    img: seo.config.homeUrl + "/public/images/closerscopy.jpg",
    type: "textGen",
    text: "Closers Copy",
    desc: "通过SEO优化的博客和不可抗拒的营销文案促进您的销售",
  },
  {
    id: "anyword",
    url: "https://anyword.com/",
    img: seo.config.homeUrl + "/public/images/anyword.jpg",
    type: "textGen",
    text: "Anyword",
    desc: "应用于演讲写作的生成式人工智能",
  },
  {
    id: "hypotenuse",
    url: "https://zh.hypotenuse.ai/",
    img: seo.config.homeUrl + "/public/images/hypotenuse.jpg",
    type: "textGen",
    text: "Hypotenuse AI",
    desc: "提供几个关键词，我们会利用我们世界一流的人工智能和丰富的知识，立即把它们变成完整的文章和营销内容。",
  },
  {
    id: "copyaiii",
    url: "https://www.copy.ai/",
    img: seo.config.homeUrl + "/public/images/copyaiii.jpg",
    type: "textGen",
    text: "copy.ai",
    desc: "AI文案创作辅助网站",
  },
  {
    id: "rytr",
    url: "https://rytr.me/",
    img: seo.config.homeUrl + "/public/images/rytr.jpg",
    type: "textGen",
    text: "Rytr",
    desc: "AI写作助手，包括邮件、博客、小说、引流标题、面试回答等",
  },
  {
    id: "grammarly",
    url: "https://www.grammarly.com/",
    img: seo.config.homeUrl + "/public/images/grammarly.png",
    type: "textGen",
    text: "Grammarly",
    desc: "立即生成清晰、引人注目的写作，同时保持您独特的风格",
  },
  {
    id: "quillbot",
    url: "https://quillbot.com/",
    img: seo.config.homeUrl + "/public/images/quillbot.jpg",
    type: "textGen",
    text: "QuillBot",
    desc: "AI多语言写作润色工具",
  },
  {
    id: "deeplwrite",
    url: "https://www.deepl.com/write",
    img: seo.config.homeUrl + "/public/images/deepl.jpg",
    type: "textGen",
    text: "DeepL write",
    desc: "DeepL的在线替换词、整句改写工具",
  },
  {
    id: "novelai",
    url: "https://novelai.net/",
    img: seo.config.homeUrl + "/public/images/novelai.jpg",
    type: "textGen",
    text: "NovelAI",
    desc: "可提供AI辅助创作、讲故事、虚拟陪伴",
  },
  {
    id: "studycorgi",
    url: "https://studycorgi.com/free-writing-tools/chat-gpt-detector/",
    img: seo.config.homeUrl + "/public/images/studycorgi.jpg",
    type: "textGen|eduAssistant",
    text: "StudyCorgi",
    desc: "针对高中、大学生，论文查重的网站",
  },
  {
    id: "copyai",
    url: "https://copyai.cn/",
    img: seo.config.homeUrl + "/public/images/copyai.jpg",
    type: "textGen|imageGen|videoGen|localAI",
    text: "Copy Done",
    desc: "AI营销内容创作，一键生成营销文本、配图与短视频等多类型内容",
  },
  {
    id: "xiezuocat",
    url: "https://xiezuocat.com/",
    img: seo.config.homeUrl + "/public/images/xiezuocat.jpg",
    type: "textGen|localAI",
    text: "秘塔写作猫",
    desc: "国产AI写作网站",
  },
  {
    id: "canvamagicwrite",
    url: "https://www.canva.com/magic-write/",
    img: seo.config.homeUrl + "/public/images/canva.png",
    type: "textGen|localAI",
    text: "Magic Write",
    desc: "canva旗下出海的AI写作工具",
  },
  {
    id: "aigaixie",
    url: "https://www.aigaixie.com/",
    img: seo.config.homeUrl + "/public/images/aigaixie.jpg",
    type: "textGen|localAI",
    text: "爱改写",
    desc: "基于NLP自然语言技术支持为您提供中英文查词解释、释义改写、同义词识别、文本纠错、内容原创度查询一站式服务",
  },
  {
    id: "aiCopy",
    url: "https://chuangyi.taobao.com/pages/aiCopy",
    img: seo.config.homeUrl + "/public/images/aiCopy.jpg",
    type: "textGen|localAI",
    text: "阿里妈妈·创意中心",
    desc: "一键生成营销文案 电商场景悉数覆盖",
  },
  {
    id: "effidit",
    url: "https://effidit.qq.com/",
    img: seo.config.homeUrl + "/public/images/effidit.jpg",
    type: "textGen|localAI",
    text: "Effidit",
    desc: "腾讯AI Lab开发的智能创作助手",
  },
  {
    id: "mypitaya",
    url: "https://www.mypitaya.com/",
    img: seo.config.homeUrl + "/public/images/mypitaya.jpg",
    type: "textGen|localAI",
    text: "火龙果",
    desc: "用火龙果，轻松写作, 可信赖的写作软件",
  },
  {
    id: "heyfriday",
    url: "https://www.heyfriday.cn/home",
    img: seo.config.homeUrl + "/public/images/heyfriday.jpg",
    type: "textGen|localAI",
    text: "Friday AI",
    desc: "无论你想写公众号文章、营销广告或者其它任何文案，让Friday AI解放你的双手",
  },
  {
    id: "yizhuan5",
    url: "https://www.yizhuan5.com/work.html#1-4",
    img: seo.config.homeUrl + "/public/images/yizhuan5.jpg",
    type: "textGen|localAI",
    text: "易撰",
    desc: "易撰自媒体工具_让内容创作更高效",
  },
  {
    id: "giiso",
    url: "https://www.giiso.com/#/",
    img: seo.config.homeUrl + "/public/images/giiso.jpg",
    type: "textGen|localAI",
    text: "Giiso写作机器人",
    desc: "一款内容创作AI辅助工具",
  },
  {
    id: "articlegenius",
    url: "https://www.5118.com/ai/articlegenius",
    img: seo.config.homeUrl + "/public/images/articlegenius.jpg",
    type: "textGen|localAI",
    text: "一键式AI文章助手",
    desc: "一键式生成高质量SEO文章，提高搜索引擎排名获得更多流量",
  },
  {
    id: "caiyunai",
    url: "https://if.caiyunai.com/dashboard/novels",
    img: seo.config.homeUrl + "/public/images/caiyunai.jpg",
    type: "textGen|localAI",
    text: "彩云小梦",
    desc: "专注AI写故事、写小说",
  },
  {
    id: "writingo",
    url: "https://www.writingo.net/document",
    img: seo.config.homeUrl + "/public/images/writingo.jpg",
    type: "textGen|localAI",
    text: "火山写作",
    desc: "字节跳动旗下的在线写作润色工具",
  },
  {
    id: "mobvoi",
    url: "https://wen.mobvoi.com/",
    img: seo.config.homeUrl + "/public/images/mobvoi.jpg",
    type: "textGen|localAI",
    text: "奇妙文",
    desc: "AI写作助手",
  },
  {
    id: "moyinx",
    url: "https://x.moyin.com/",
    img: seo.config.homeUrl + "/public/images/moyin.jpg",
    type: "textGen|localAI",
    text: "魔撰写作",
    desc: "AI文案提取、文章润色、段落纠错",
  },
  {
    id: "youdao",
    url: "https://write.youdao.com/#/homepage",
    img: seo.config.homeUrl + "/public/images/youdao.jpg",
    type: "textGen|localAI",
    text: "有道写作",
    desc: "智能修改, 实现完美英文写作",
  },
  {
    id: "vgoapp",
    url: "https://vgoapp.com/",
    img: seo.config.homeUrl + "/public/images/vgoapp.png",
    type: "textGen|officeAssistant",
    text: "字符狂飙",
    desc: "一款基于人工智能技术的文档生成应用",
  },
  {
    id: "notion",
    url: "https://www.notion.so/product/ai",
    img: seo.config.homeUrl + "/public/images/notion.png",
    type: "textGen|officeAssistant",
    text: "Notion",
    desc: "Notion 具有独特且高度灵活的拖放文本编辑器，可以轻松地重新排列和转换任何AI生成的内容",
  },
  {
    id: "bearly",
    url: "https://bearly.ai/",
    img: seo.config.homeUrl + "/public/images/bearly.jpg",
    type: "textGen|officeAssistant",
    text: "Bearly",
    desc: "将最先进的人工智能添加到您的工作流程中,辅助阅读、写作和内容创作",
  },
  {
    id: "deepl",
    url: "https://www.deepl.com/translator",
    img: seo.config.homeUrl + "/public/images/deepl.jpg",
    type: "textGen|officeAssistant|eduAssistant",
    text: "DeepL 翻译器",
    desc: "AI文件翻译",
  },
  {
    id: "bing",
    url: "https://www.bing.com/translator",
    img: seo.config.homeUrl + "/public/images/bing.jpg",
    type: "textGen|officeAssistant|eduAssistant",
    text: "必应翻译",
    desc: "微软翻译工具",
  },
  {
    id: "alibabaTran",
    url: "https://translate.alibaba.com/",
    img: seo.config.homeUrl + "/public/images/alibabaTran.jpg",
    type: "textGen|officeAssistant|eduAssistant|localAI",
    text: "阿里翻译",
    desc: "阿里巴巴翻译，包括文本、文档、图片、视频、语音翻译",
  },
  {
    id: "volcengine",
    url: "https://translate.volcengine.com/",
    img: seo.config.homeUrl + "/public/images/volcengine.jpg",
    type: "textGen|officeAssistant|eduAssistant|localAI",
    text: "火山翻译",
    desc: "字节跳动翻译，包括通用领域、跨境电商等翻译",
  },
  {
    id: "baidufanyi",
    url: "https://fanyi.baidu.com/appdownload/download.html",
    img: seo.config.homeUrl + "/public/images/baidufanyi.jpg",
    type: "textGen|officeAssistant|eduAssistant|localAI",
    text: "百度翻译",
    desc: "百度AI同传助手, 音视频同传字幕工具",
  },
  {
    id: "sogoufanyi",
    url: "https://fanyi.sogou.com/text",
    img: seo.config.homeUrl + "/public/images/sogoufanyi.jpg",
    type: "textGen|officeAssistant|eduAssistant|localAI",
    text: "搜狗翻译",
    desc: "搜狗文档翻译、图片翻译",
  },
  {
    id: "caiyunapp",
    url: "https://fanyi.caiyunapp.com/#/",
    img: seo.config.homeUrl + "/public/images/caiyunapp.jpg",
    type: "textGen|officeAssistant|eduAssistant|localAI",
    text: "彩云小译",
    desc: "专业术语翻译、在线翻译工具",
  },
  {
    id: "xfyun",
    url: "https://zj.xfyun.cn/exam/text",
    img: seo.config.homeUrl + "/public/images/vgoapp.jpg",
    type: "textGen|officeAssistant|localAI",
    text: "讯飞智检",
    desc: "科大讯飞旗下的智能AI写作、文档纠错校对、文本图像合规检测平台",
  },
  {
    id: "runwayml",
    url: "https://app.runwayml.com/dashboard",
    img: seo.config.homeUrl + "/public/images/runwayml.jpg",
    type: "videoGen",
    text: "Runway",
    desc: "Runway的人工智能生成视频平台",
  },
  {
    id: "runwaymlgs",
    url: "https://runwayml.com/green-screen/",
    img: seo.config.homeUrl + "/public/images/runwayml.jpg",
    type: "videoGen",
    text: "Green Screen",
    desc: "Runway的视频背景消除、绿幕抠像、视频生成工具",
  },
  {
    id: "wonderdynamics",
    url: "https://wonderdynamics.com/",
    img: seo.config.homeUrl + "/public/images/wonderdynamics.jpg",
    type: "videoGen",
    text: "Wonder Studio",
    desc: "一款AI工具，可自动将CG角色制作成动画、灯光并组合成真人场景",
  },
  {
    id: "artflow",
    url: "https://app.artflow.ai/",
    img: seo.config.homeUrl + "/public/images/artflow.jpg",
    type: "videoGen",
    text: "Artflow",
    desc: "让用户借助AI实现创意愿景",
  },
  {
    id: "unscreen",
    url: "https://www.unscreen.com/",
    img: seo.config.homeUrl + "/public/images/unscreen.jpg",
    type: "videoGen",
    text: "Unscreen",
    desc: "消除视频背景的AI工具",
  },
  {
    id: "ebsynth",
    url: "https://ebsynth.com/",
    img: seo.config.homeUrl + "/public/images/ebsynth.jpg",
    type: "videoGen",
    text: "Ebsynth",
    desc: "将真人视频转化为油画风动画的AI网站",
  },
  {
    id: "kaiber",
    url: "https://kaiber.ai/",
    img: seo.config.homeUrl + "/public/images/kaiber.jpg",
    type: "videoGen",
    text: "Kaiber",
    desc: "图片文字转视频的AI网站",
  },
  {
    id: "dreamfaceapp",
    url: "https://dreamfaceapp.com/",
    img: seo.config.homeUrl + "/public/images/dreamfaceapp.jpg",
    type: "videoGen",
    text: "DreamFace",
    desc: "图片转动态图片平台",
  },
  {
    id: "fliki",
    url: "https://fliki.ai/",
    img: seo.config.homeUrl + "/public/images/fliki.jpg",
    type: "videoGen",
    text: "Fliki",
    desc: "使用真实的声音根据脚本或博客文章在2分钟内完成视频创建",
  },
  {
    id: "deepbrain",
    url: "https://www.deepbrain.io/",
    img: seo.config.homeUrl + "/public/images/deepbrain.jpg",
    type: "videoGen",
    text: "DEEPBRAIN.AI",
    desc: "只需一个脚本即可创建生成式AI视频",
  },
  {
    id: "lumen5",
    url: "https://lumen5.com/",
    img: seo.config.homeUrl + "/public/images/lumen5.jpg",
    type: "videoGen",
    text: "Lumen5",
    desc: "拖拽即可生成AI视频",
  },
  {
    id: "synthesia",
    url: "https://www.synthesia.io/",
    img: seo.config.homeUrl + "/public/images/synthesia.jpg",
    type: "videoGen",
    text: "Synthesia",
    desc: "AI生成专业口播视频",
  },
  {
    id: "rephrase",
    url: "https://www.rephrase.ai/",
    img: seo.config.homeUrl + "/public/images/rephrase.jpg",
    type: "videoGen",
    text: "Rephrase.ai",
    desc: "AI文本生视频",
  },
  {
    id: "videobgremover",
    url: "https://www.veed.io/zh-CN/tools/video-background-remover",
    img: seo.config.homeUrl + "/public/images/veed.jpg",
    type: "videoGen",
    text: "VEED.IO",
    desc: "Veed旗下的一键删除视频背景工具",
  },
  {
    id: "hourone",
    url: "https://hourone.ai/",
    img: seo.config.homeUrl + "/public/images/hourone.jpg",
    type: "videoGen",
    text: "Hour One",
    desc: "商业AI视频生成器",
  },
  {
    id: "bgrem",
    url: "https://bgrem.ai/zh/remove-video-bg/",
    img: seo.config.homeUrl + "/public/images/bgrem.jpg",
    type: "videoGen",
    text: "BgRem",
    desc: "AI创作和编辑视频，100%自动使用人工智能，无需绿幕",
  },
  {
    id: "colourlab",
    url: "https://colourlab.ai/",
    img: seo.config.homeUrl + "/public/images/colourlab.jpg",
    type: "videoGen",
    text: "Colourlab Ai",
    desc: "好莱坞也在用的人工智能色彩分级软件",
  },
  {
    id: "cutoutremovevideobg",
    url: "https://www.cutout.pro/zh-CN/remove-video-background",
    img: seo.config.homeUrl + "/public/images/cutout.jpg",
    type: "videoGen",
    text: "cutout.pro",
    desc: "cutout.pro旗下的一键去除视频背景，100%自动，在线去除视频背景",
  },
  {
    id: "colossyan",
    url: "https://www.colossyan.com/",
    img: seo.config.homeUrl + "/public/images/colossyan.jpg",
    type: "videoGen",
    text: "Colossyan",
    desc: "用于工作场所培训的人工智能视频平台",
  },
  {
    id: "avclabs",
    url: "https://app.avclabs.com/#/",
    img: seo.config.homeUrl + "/public/images/avclabs.jpg",
    type: "videoGen",
    text: "AVCLabs",
    desc: "100%免费和自动AI消除视频背景网站",
  },
  {
    id: "elai",
    url: "https://elai.io/",
    img: seo.config.homeUrl + "/public/images/elai.jpg",
    type: "videoGen",
    text: "Elai",
    desc: "无需绿幕，文字即可生成AI视频",
  },
  {
    id: "pictory",
    url: "https://pictory.ai/",
    img: seo.config.homeUrl + "/public/images/pictory.jpg",
    type: "videoGen",
    text: "Pictory",
    desc: "根据长内容自动创建品牌营销AI短视频",
  },
  {
    id: "steve",
    url: "https://www.steve.ai/",
    img: seo.config.homeUrl + "/public/images/steve.jpg",
    type: "videoGen",
    text: "Steve AI",
    desc: "知名AI文生视频平台",
  },
  {
    id: "did",
    url: "https://www.d-id.com/",
    img: seo.config.homeUrl + "/public/images/did.jpg",
    type: "videoGen|digitalHuman",
    text: "D-ID",
    desc: "AI文字转数字人口播视频工具",
  },
  {
    id: "synthesys",
    url: "https://synthesys.io/",
    img: seo.config.homeUrl + "/public/images/synthesys.jpg",
    type: "videoGen|digitalHuman",
    text: "Synthesys",
    desc: "AI虚拟人视频极速出片",
  },
  {
    id: "heygen",
    url: "https://www.heygen.com/?from=moviola",
    img: seo.config.homeUrl + "/public/images/heygen.jpg",
    type: "videoGen|digitalHuman",
    text: "HeyGen",
    desc: "下一代视频创作平台，文字转专业代言人视频",
  },
  {
    id: "gencraft",
    url: "https://gencraft.com/",
    img: seo.config.homeUrl + "/public/images/gencraft.jpg",
    type: "videoGen|imageGen",
    text: "Gencraft",
    desc: "AI图片、AI视频生成平台",
  },
  {
    id: "zenvideo",
    url: "https://zenvideo.qq.com/",
    img: seo.config.homeUrl + "/public/images/zenvideo.jpg",
    type: "videoGen|localAI",
    text: "腾讯智影",
    desc: "素材搜集、视频剪辑、后期包装、渲染导出和发布于一体的在线剪辑平台",
  },
  {
    id: "huiyingzimu",
    url: "https://huiyingzimu.com/",
    img: seo.config.homeUrl + "/public/images/huiyingzimu.jpg",
    type: "videoGen|localAI",
    text: "绘影字幕",
    desc: "一键在线自动为视频加字幕",
  },
  {
    id: "taishan",
    url: "https://taishan.qq.com/brand/",
    img: seo.config.homeUrl + "/public/images/taishan.jpg",
    type: "videoGen|localAI",
    text: "腾讯云智绘",
    desc: "腾讯旗下的AI视频剪辑、智能抠图、人像动漫化",
  },
  {
    id: "neurodub",
    url: "https://neurodub.ai/",
    img: seo.config.homeUrl + "/public/images/neurodub.png",
    type: "videoGen|musicGen",
    text: "Neurodub",
    desc: "端到端AI视频本地化和性价比AI配音",
  },
  {
    id: "rask",
    url: "https://zh.rask.ai/",
    img: seo.config.homeUrl + "/public/images/rask.jpg",
    type: "videoGen|musicGen",
    text: "RASK",
    desc: "AI视频本地化和配音工具",
  },
];